import { Button, Typography } from "@material-ui/core";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import React from "react";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import CompanyUtil from "../../shared/utils/CompanyUtil";
import UserUtil from "../../shared/utils/UserUtil";
import { bindActionCreators } from "redux";
import DisputeHelpDialog from "./DisputeHelpDialog";

class DisputeHelpButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisputeHelpDialog: false,
    };
  }

  render() {
    const { userInfo } = this.props;
    const { showDisputeHelpDialog } = this.state;

    

    return (
      CompanyUtil.allowParkDisputesHelp(UserUtil.getCompany(userInfo)) && (
        <div style={{ display: "inline-flex" }}>
          <Button
            onClick={() => this.setState({ showDisputeHelpDialog: true })}
            startIcon={
              <EmojiPeopleIcon
                color="secondary"
                
              />
            }
          >
            <Typography variant="body1">{TranslatorUtil.t("Parking Dispute")}</Typography>
          </Button>

          {showDisputeHelpDialog && (
            <DisputeHelpDialog
            onClose={() => this.setState({ showDisputeHelpDialog: false })}
            />
          )}
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(DisputeHelpButton)
);
