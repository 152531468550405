import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as profileActions from "../../actions/profile";
import React from "react";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";
import ArrayUtil from "../../shared/utils/general/ArrayUtil";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import UserUtil from "../../shared/utils/UserUtil";
import { bindActionCreators } from "redux";
import ValidationUtil from "../../utils/ValidationUtil";

class UserNumberPlatesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberPlate1: "",
      numberPlate2: "",
      numberPlate3: "",
      numberPlate1Error: false,
      numberPlate2Error: false,
      numberPlate3Error: false,
    };
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate", this.props, prevProps);
    if (this.props.profile?.id !== prevProps.profile?.id) {
      this.mountNumberPlates();
    }
  }

  componentDidMount() {
    this.mountNumberPlates();
  }

  mountNumberPlates = () => {
    const { profile } = this.props;
    console.log("mountNumberPlates");
    if (ArrayUtil.isNonEmptyArray(profile.numberPlates)) {
      for (let i = 0; i < profile.numberPlates.length; i++) {
        const plate = profile.numberPlates[i];
        if (plate && plate.length > 0) {
          this.setState({ [`numberPlate${i + 1}`]: plate });
        }
      }
    }
  };


  handleSave = () => {
    const { enqueueSnackbar, actions, profile } = this.props;
    const { numberPlate1, numberPlate2, numberPlate3 } = this.state;

    if (
      [numberPlate1, numberPlate2, numberPlate3].filter(
        (x) => !ValidationUtil.isPlateNumberValid(x)
      ).length < 1
    ) {
      const plates = [numberPlate1, numberPlate2, numberPlate3].filter(
        (plate, index) => plate && plate.length > 0
      );
      console.log("handleSave", plates);
      actions
        .patchProfile(profile.id, { numberPlates: plates })
        .then((response) => {
          enqueueSnackbar(TranslatorUtil.t("Number plates updated"), {
            variant: "success",
          });

          this.props.onClose();
        });
    } else {
      [numberPlate1, numberPlate2, numberPlate3].forEach((plate, index) => {
        if (!ValidationUtil.isPlateNumberValid(plate)) {
          this.setState({ [`numberPlate${index + 1}Error`]: true });
        } else {
          this.setState({ [`numberPlate${index + 1}Error`]: false });
        }
      });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  isMyCarUpdate = () => {
    const { profile, userInfo } = this.props;
    return profile.id === UserUtil.getId(userInfo);
  };

  handleChange = (e, key) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    this.setState({ [key]: value.toUpperCase() });
  };

  render() {
    const { profile } = this.props;
    const { numberPlate1, numberPlate2, numberPlate3 } = this.state;
    const { numberPlate1Error, numberPlate2Error, numberPlate3Error } =
      this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
      >
        <DialogTitle id="simple-dialog-title" disableTypography={true}>
          <Typography variant="h5" color="primary">
            {TranslatorUtil.t("Update plate numbers")}
          </Typography>
        </DialogTitle>
        <div>
          <Box px={3} py={0}>
            {this.isMyCarUpdate() ? null : (
              <Box className="text-muted">{profile.email}</Box>
            )}
            <Box pt={this.isMyCarUpdate() ? 0 : 2} pl={1}>
              <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <DirectionsCarIcon
                  style={{ marginTop: "-3px" }}
                  fontSize="small"
                  color="secondary"
                />
                <Box pl={1}>
                  <Typography color="secondary">
                    {" "}
                    {this.isMyCarUpdate()
                      ? TranslatorUtil.t("My cars")
                      : TranslatorUtil.t("Cars")}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Box>
          <Box px={1} py={0}>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    <TextField
                      label={TranslatorUtil.t("Number plate")}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => this.handleChange(e, "numberPlate1")}
                      value={numberPlate1}
                      error={numberPlate1Error}
                      helperText={
                        numberPlate1Error &&
                        TranslatorUtil.t(
                          "Please enter valid number plate (3-10 characters)"
                        )
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <TextField
                      label={TranslatorUtil.t("Number plate")}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => this.handleChange(e, "numberPlate2")}
                      value={numberPlate2}
                      error={numberPlate2Error}
                      helperText={
                        numberPlate2Error &&
                        TranslatorUtil.t(
                          "Please enter valid number plate (3-10 characters)"
                        )
                      }
                    />
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <TextField
                      label={TranslatorUtil.t("Number plate")}
                      inputProps={{ maxLength: 10 }}
                      onInput={(e) => this.handleChange(e, "numberPlate3")}
                      value={numberPlate3}
                      error={numberPlate3Error}
                      helperText={
                        numberPlate3Error &&
                        TranslatorUtil.t(
                          "Please enter valid number plate (3-10 characters)"
                        )
                      }
                    />
                  }
                />
              </ListItem>
            </List>
          </Box>
          <Box p={2}>
            <Grid
              spacing={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="text" onClick={this.handleClose}>
                {TranslatorUtil.t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSave}
              >
                {TranslatorUtil.t("Save")}
              </Button>
            </Grid>
          </Box>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...profileActions,
      },
      dispatch
    ),
  };
}

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(UserNumberPlatesDialog)
);
