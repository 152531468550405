import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as parkActions from "../../../actions/park";
import * as profileActions from "../../../actions/profile";
import * as parkOwnershipActions from "../../../actions/parkOwnerships";
import * as bookingSelectionActions from "../../../actions/bookingSelection";

import objectPath from 'object-path';
import ParkingWeeksTable from '../../../components/park/ParkingWeeksTable';
import { ButtonGroup, Button, Box, Grid, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LinkRef from '../../../components/LinkRef';
import ComponentLoading from '../../../components/ComponentLoading';
import PageHeading from '../../../components/PageHeading';
import AddIcon from '@material-ui/icons/Add';
import ParksUsageStatsBar from '../../../components/park/ParksUsageStatsBar';
import ParksBulkActions from '../../../components/park/ParksBulkActions';

import urls from '../../../shared/constants/urls';
import DateUtil from '../../../shared/utils/general/DateUtil';
import UserUtil from '../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../shared/utils/general/ArrayUtil';
import ParkingSecondaryActions from '../../../components/park/ParkingSecondaryActions';
import WeekSelectDialog from '../../../components/WeekSelectDialog';
import ServerTimeUtil from '../../../utils/ServerTimeUtil';
import DisputeHelpButton from '../../../components/user/DisputeHelpButton';

class Dashboard extends React.Component {
  


  constructor (props) {
    super(props);
    this.state = {
      appLoading: true,
      weeksOpen: false,
      days: [],
      highlightMe: UserUtil.isNormalUser(this.props.userInfo)
    }   
  }

  componentDidMount() {
    
    let _this = this;
      this.getDays();
      Promise.all([
        
      ]).then(() => {
        Promise.all([
          
        ]).then(()=>{
          console.log('loading done');
          _this.setState({appLoading: false});

          //catch errors
        }).catch(function(err) {
          console.log('loading error',err);
        });
      }).catch(function(err) {
        console.log('loading error',err);
      });
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.setBookingSelection(false);
  }

  getDays = () => {
    const { userInfo } = this.props;
    this.setState({
      days: DateUtil.getTwoWeeks(this.getRequestedMomentDate(), true, UserUtil.includeWeekend(userInfo))
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    
    var date = objectPath.get(this,'props.match.params.date');
    var prevDate = objectPath.get(prevProps,'match.params.date');
    if (date !== prevDate) {
      this.getDays();
    }
  }

  getRequestedMomentDate = () => {
    const { userInfo } = this.props;
    return DateUtil.getURLdate(objectPath.get(this,'props.match.params.date', null), UserUtil.includeWeekend(userInfo));
  }


  getNavigationLink = (future) => {
    var date = this.getRequestedMomentDate();
    var urlDate = DateUtil.dbFormat(future ? date.clone().startOf('week').add(1,'week') : date.clone().startOf('week').subtract(1,'week'));
    return urls.DASHBOARD_URL(urlDate);
  }

  isCalendarNavigationDisabled = () => {
    const { bookingSelectionEnabled, bookingSelection } = this.props;
    return bookingSelectionEnabled && bookingSelection && bookingSelection.length ? true : false;
  }

  hasDisabledFutureDay = (days) => {
    const { userInfo } = this.props;
    return days.filter(d => UserUtil.isDisallowedFuture(d.date, userInfo, ServerTimeUtil.getUserCompanyTimezone(), ServerTimeUtil.getServerTimeOffset())).length > 0;
  }

  handleHighlightMeChange = () => {
    return this.setState({highlightMe : !this.state.highlightMe});
  }

  getAddParksHelpMessage = () => {
    const { companyParks } = this.props;
    var parksCount = companyParks.length;
    if(parksCount === 0){
      return TranslatorUtil.t("Let's add some parks!");
    } else if(parksCount < 2){
      return TranslatorUtil.t("Let's add some more parks!");
    }
    return null;
  }

  getAddUsersHelpMessage = () => {
    const { companyInvitations, companyProfiles, companyParks } = this.props;
    var parksCount = companyParks.length;
    var totalCount = companyProfiles.length + companyInvitations.length;
    if(parksCount > 0 && totalCount < 2){
      return TranslatorUtil.t("Let's invite some users and share parks!");
    } else if(parksCount > 0 && totalCount < 3){
      return TranslatorUtil.t("Let's invite some more users and share parks!");
    }
    return null;
  }
  
  render() {

    const { companyParks,userInfo } = this.props;
    const { appLoading, days, highlightMe, weeksOpen } = this.state;

    return <div className="app-container-wrap">
        <PageHeading icon={<EventNoteIcon />} title={TranslatorUtil.t("Calendar view")} />
        
        {appLoading ? <ComponentLoading /> : ArrayUtil.isNonEmptyArray(companyParks) ? <React.Fragment>
          <Grid container spacing={4}>
              <Grid item xs={12} sm={5} style={{display: 'flex'}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={highlightMe}
                    onChange={this.handleHighlightMeChange}
                    color="primary"
                  />
                }
                label={TranslatorUtil.t("Highlight my bookings")}
              />
              <DisputeHelpButton />
              </Grid>
              <Grid item xs={12} sm={7}>
              <Box textAlign="end" className="weeks-filter-wrap">
                <ButtonGroup variant="contained" size="large" color="primary">
                    <Button component={LinkRef} disabled={this.isCalendarNavigationDisabled()} to={this.getNavigationLink(false)} className="prev"><ArrowBackIcon /></Button>
                    <Button disabled={!UserUtil.isAtLeastCompanyManager(userInfo)} onClick={() => this.setState({weeksOpen : true})} className="display-date">{DateUtil.getRangeFormat(days[0].date,days[days.length-1].date)}</Button>
                    <Button component={LinkRef} disabled={this.isCalendarNavigationDisabled() || this.hasDisabledFutureDay(days)} to={this.getNavigationLink(true)} className="next"><ArrowForwardIcon /></Button>
                  </ButtonGroup>
                  {weeksOpen ? <WeekSelectDialog selectedDate={days[0].date} onClose={() => this.setState({ weeksOpen: false })} /> : null}
                </Box>
              </Grid>
            </Grid>
          
        { UserUtil.isAtLeastCompanyManager(userInfo) && <ParksUsageStatsBar days={days} /> }
        <ParkingSecondaryActions />
        <ParksBulkActions startLoading={() => this.setState({appLoading: true})} finishLoading={() => this.setState({appLoading: false})} />
        <ParkingWeeksTable days={days} day={DateUtil.dbFormat(days[0].date)} highlightMe={highlightMe} companyId={null} appAdminView={false} />
        
        
        

          </React.Fragment> : TranslatorUtil.t("We cannot find any parks for your company.")}
        
          {UserUtil.isAtLeastCompanyManager(userInfo) ? 
            <Grid container spacing={4} className="buttonsGridWrap">
                <Grid item xs={12} sm={6}>
                <Box py={2}>
                {
            this.getAddParksHelpMessage() && !appLoading ? <Tooltip open title={this.getAddParksHelpMessage()} arrow placement="bottom" classes={{
              tooltip: 'introTooltip pulsing',
            }}>
              <Button color="primary" variant="contained" component={LinkRef} to={urls.PARK_URL('create')} startIcon={<AddIcon />}>{TranslatorUtil.t("Add a new park")}</Button>
            </Tooltip> : <Button size="large" color="primary" variant="contained" component={LinkRef} to={urls.PARK_URL('create')} startIcon={<AddIcon />}>{TranslatorUtil.t("Add a new park")}</Button>
          }
          </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                <Box py={2}>
                {
            
            this.getAddUsersHelpMessage() && !appLoading ? <Tooltip open title={this.getAddUsersHelpMessage()} arrow placement="bottom" classes={{
              tooltip: 'introTooltip pulsing',
            }}>
              <Button size="large" color="secondary" variant="contained" component={LinkRef} to={urls.COMPANY_USERS_URL} startIcon={<AddIcon />}>{TranslatorUtil.t("Invite users")}</Button>
            </Tooltip> : null
          }</Box>
                </Grid>
              </Grid>
          : null}
        
      </div>
  }
}



function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    companyParks: state.companyParks,
    companyInvitations: state.companyInvitations,
    companyProfiles: state.companyProfiles,
    bookingSelection: state.bookingSelection,
    bookingSelectionEnabled: state.bookingSelectionEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...parkActions,
        ...profileActions,
        ...parkOwnershipActions,
        ...bookingSelectionActions
      },
      dispatch
    )
  };
}


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard));
