import React from 'react';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as profileActions from "../../../../actions/profile";
import * as parkActions from "../../../../actions/park";
import * as invitationActions from "../../../../actions/invitation";
import ComponentLoading from '../../../../components/ComponentLoading';

import PageHeading from '../../../../components/PageHeading';
import PeopleIcon from '@material-ui/icons/People';
import objectPath from 'object-path';
import SnackBarUtil from '../../../../utils/SnackBarUtil';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { withSnackbar } from "notistack";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IconButton, Box, Typography, Button, FormControlLabel, Switch } from '@material-ui/core';
import AppSortableTable from '../../../../components/table/AppSortableTable';
import ClearIcon from '@material-ui/icons/Clear';
import InvitationForm from '../../../../forms/company/invitation/form';
import ValidationUtil from '../../../../utils/ValidationUtil';

import UserUtil from '../../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../../shared/utils/general/TranslatorUtil';
import ArrayUtil from '../../../../shared/utils/general/ArrayUtil';
import UIFormatterUtil from '../../../../utils/UIFormatterUtil';
import PlatformIcon from '../../../../components/icons/PlatformIcon';
import DateUtil from '../../../../shared/utils/general/DateUtil';
import RoleIcon from '../../../../components/user/RoleIcon';
import CompanyUtil from '../../../../shared/utils/CompanyUtil';
import ProfilePlateNumbers from '../../../../components/user/ProfilePlateNumbers';


class CompanyUsers extends React.Component {



  constructor(props) {
    super(props);
    this.state = {
      listDataLoading: true,
      tabId: 0,
      invitationToRemove: null,
      userToDisable: null,
      showConfirmation: false,
      confirmTitle: "",
      confirmMessage: "",
      invitationsInProcess: false,
      excludeDisabledUsers: true,
    }
  }

  componentDidMount() {
    const { actions,userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);
    let _this = this;
    if (company) {
      Promise.all([
        actions.getProfilesByCompanyId(company.companyId),
        actions.getInvitationsByCompanyId(company.companyId),
        actions.getParksByCompanyId(company.companyId),
      ]).then(() => {
        console.log('loading done');
        _this.setState({ listDataLoading: false });

        //catch errors
      }).catch(function (err) {
        console.log('loading error', err);
        _this.setState({ listDataLoading: false });
      });
    }


  }

  handleResponse = (response, customMessage) => {
    const { enqueueSnackbar } = this.props;
    if (!SnackBarUtil.isResponseError(response, enqueueSnackbar)) {
      var successMessage = customMessage ? customMessage : TranslatorUtil.t("Successfully processed.");
      enqueueSnackbar(successMessage, {
        variant: "success"
      });
    }
  }

  emailAlreadyExists = (email) => {
    const { companyProfiles, companyInvitations } = this.props;
    var profilesAndInvitations = (companyProfiles || []).concat(companyInvitations || []);
    // console.log('profilesAndInvitations', profilesAndInvitations)
    var emails = ArrayUtil.isNonEmptyArray(profilesAndInvitations) ? profilesAndInvitations.filter(x => x.email).map(x => x.email) : [];
    return emails.indexOf(email) > -1; 
  }

  validateInvitations = (invitationsInputValue) => {
    const { enqueueSnackbar } = this.props;
    var invitations = [];
    var validInvitations = [];
    var rawInput = invitationsInputValue.toLowerCase().replace(/\s/g,'');
    if(rawInput){
      if(rawInput.indexOf(',') > -1){
        invitations = rawInput.split(',');
      } else {
        invitations.push(rawInput);
      }
    }
    if(ArrayUtil.isNonEmptyArray(invitations)){
      invitations.forEach(i => {
        var errorMessage = "";
        var emailValid = ValidationUtil.isValidEmail(i);
        var overLimit = validInvitations.length >= 10;
        var alreadyExists = this.emailAlreadyExists(i);

        if(overLimit){
          errorMessage = i + " - " + TranslatorUtil.t("invitation limit per request exceeded");
        }
        if(alreadyExists){
          errorMessage = i + " - " + TranslatorUtil.t("invitation or user already exists");
        }
        if(!emailValid){
          errorMessage = i + " - " + TranslatorUtil.t("Invalid format");
        }

        if(emailValid && !overLimit && !alreadyExists){
          validInvitations.push(i);
        } else {
          enqueueSnackbar(errorMessage, {
            variant: "error"
          });
        }
      })
      
      
    }
    return validInvitations;
  }



  handleSubmit = (values) => {
    const { actions, userInfo } = this.props;
    var invitations = this.validateInvitations(values.invitations);
    const company = UserUtil.getCompany(userInfo);
    
    if(ArrayUtil.isNonEmptyArray(invitations)){
      console.log('add invitations',invitations);
      this.setState({ invitationsInProcess : true });
      return Promise.all(invitations.map(
        invitation => actions.postInvitation({
          companyId: company.companyId,
          email:invitation,
          accepted: false,
          createdUnix: DateUtil.getCurrentUnix()
        }).then(response => this.handleResponse(response, invitation+" "+TranslatorUtil.t("successfully invited.")))
      )).then( () => {
        this.setState({ invitationsInProcess : false });
        actions.getInvitationsByCompanyId(company.companyId);
      }); 
    }
    
  };

  handleChangeTab = (event, value) => {
    this.setState({ tabId: value });
  }


  confirmInvitationRemove = (item) => {
    this.setState({
      invitationToRemove: item,
      showConfirmation: true,
      confirmTitle: TranslatorUtil.t("Are you sure?"),
      confirmMessage: TranslatorUtil.t("Please confirm removal of invitation for ") + item.email
    });
  }

  confirmOnInvitationCancel = () => {
    this.setState({
      invitationToRemove: null,
      showConfirmation: false,
      confirmTitle: "",
      confirmMessage: ""
    });
  }

  confirmOnInvitationConfirm = () => {
    const { actions } = this.props;
    const { invitationToRemove } = this.state;
    return actions.deleteInvitation(invitationToRemove.id).then(() => {
      this.confirmOnInvitationCancel();
      actions.getInvitationsByCompanyId(invitationToRemove.companyId);
    });
  }

  confirmUserDisable = (item) => {
    var isDisabled = item.disabled === true;
    this.setState({
      userToDisable: item,
      showConfirmation: true,
      confirmTitle: TranslatorUtil.t("Are you sure?"),
      confirmMessage: TranslatorUtil.t("Please confirm you want to " + (isDisabled ? "enable" : "disable") + " user ") + item.email
    });
  }

  confirmOnUserCancel = () => {
    this.setState({
      userToDisable: null,
      showConfirmation: false,
      confirmTitle: "",
      confirmMessage: ""
    });
  }

  confirmOnUserConfirm = () => {
    const { actions } = this.props;
    const { userToDisable } = this.state;
    var isDisabled = userToDisable.disabled === true;
    return actions.patchProfile(userToDisable.id, { disabled: !isDisabled }).then((response) => {
      this.confirmOnUserCancel();
      this.handleResponse(response, isDisabled ? TranslatorUtil.t("User has been enabled") : TranslatorUtil.t("User has been disabled"));
      actions.getProfilesByCompanyId(userToDisable.companyId);
    });
  }

  getTableRows = () => {
    const { tabId, invitationsInProcess, excludeDisabledUsers } = this.state;
    const { companyProfiles, companyInvitations, userInfo, companyParks } = this.props;
    const company = UserUtil.getCompany(userInfo);
    if (tabId === 0) {
      return ArrayUtil.isNonEmptyArray(companyInvitations) ? ArrayUtil.sortObjectsAlphabetically(companyInvitations,'email').map(invitation => {
        return {
          item: invitation,
          items: [
            {
              text: invitation.email
            },
            // {
            //   text: invitation.accepted === true ? <CheckIcon /> : ' ', 
            // },
            {
              text: companyProfiles.find(x => x.email === invitation.email) ? <CheckIcon /> : <ClearIcon />
            },
          ],
          actions: <IconButton size="small" onClick={() => this.confirmInvitationRemove(invitation)}>
            <DeleteIcon />
          </IconButton>
        }
      }) : [];
    }
    if (tabId === 1) {
      var filteredCompanyProfiles = companyProfiles.filter(x => ((excludeDisabledUsers && objectPath.get(x,'disabled',false) !== true) || !excludeDisabledUsers));
      return ArrayUtil.isNonEmptyArray(filteredCompanyProfiles) ? filteredCompanyProfiles.map(profile => {
        var ownedParks = UserUtil.getOwnedParksFromParksByProfile(companyParks,profile);
        var _profile = {
          ...profile,
          roleType: UserUtil.getUserTypeFromProfile(company,profile),
          ownedParks: !ArrayUtil.isNonEmptyArray(ownedParks) ? '-' : ownedParks.map(x => x.parkId).join(',')
        };
        return {
          item: _profile,
          items: [
            {
              text: profile.email
            },
            {
              text: _profile.ownedParks
            },
            {
              text: <ProfilePlateNumbers profile={_profile} reloadAfterSave={false} showTextButton={false} /> ,
                additionalClasses: !CompanyUtil.allowParkDisputesHelp(company) ? "hidden" : "hidden-md-down"
            },
            {
              text: <RoleIcon roleType={_profile.roleType} />
            },
            {
              text: <PlatformIcon profile={profile} />,
              additionalClasses: "hidden-md-down"
            },
            {
              text: UIFormatterUtil.getUserAppDetails(profile),
              additionalClasses: "hidden-md-down"
            },
            {
              text: profile.updatedUnix ? DateUtil.formatDateTimeShort(profile.updatedUnix) : '-',
              additionalClasses: "hidden-md-down"
            },
          ],
          actions: UserUtil.isCompanyAdminByProfile(profile, userInfo.company) ? 
                    <Typography variant="caption">{TranslatorUtil.t("Admin")}</Typography> : 
                    <Button disabled={invitationsInProcess} variant="contained" className={profile.disabled ? "success-btn" : "error-btn"} onClick={() => this.confirmUserDisable(profile)} startIcon={profile.disabled ? <CheckCircleIcon size="small" /> : <BlockIcon size="small" />}>
                    {profile.disabled ? "Enable" : "Disable"}
                  </Button>
        }
      }) : [];
    }
    return [];

  }

  getActionHeader = () => {
    const { tabId } = this.state;
    var invitationsHeader = TranslatorUtil.t("Remove");
    var usersHeader = TranslatorUtil.t("Disable/Enable");

    return tabId === 0 ? invitationsHeader : tabId === 1 ? usersHeader : "";
  }

  getTableHeaders = () => {
    const { tabId } = this.state;
    const { userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);
    var invitationsHeaders = [
      { id: 'email', numeric: false, text: TranslatorUtil.t("Email") },
      {id: 'accepted', numeric: false,  text: TranslatorUtil.t("Accepted") },
    ];
    var usersHeaders = [
      { id: 'email', numeric: false,  text: TranslatorUtil.t("Email") },
      { id: 'ownedParks', numeric: false,  text: TranslatorUtil.t("Current park owner") },
      { id: 'numberPlates', numeric: false,  text: TranslatorUtil.t("Number plates"), sortable: false, additionalClasses: !CompanyUtil.allowParkDisputesHelp(company) ? "hidden" : "hidden-md-down" },
      { id: 'roleType', numeric: true,  text: TranslatorUtil.t("Role") },
      { id: 'messagingPlatform', numeric: false,  text: TranslatorUtil.t("OS"),
      additionalClasses: "hidden-md-down" },
      { id: 'appVersion', numeric: false,  text: TranslatorUtil.t("Version"),
      additionalClasses: "hidden-md-down" },
      { id: 'updatedUnix', numeric: true,  text: TranslatorUtil.t("Updated"),
      additionalClasses: "hidden-md-down" },
    ];

    return tabId === 0 ? invitationsHeaders : tabId === 1 ? usersHeaders : [];
  }




  render() {

    const { userInfo } = this.props;
    const { listDataLoading, tabId,
      invitationsInProcess,
      invitationToRemove,
      userToDisable,
      showConfirmation,
      confirmTitle,
      excludeDisabledUsers,
      confirmMessage } = this.state;
    var company = UserUtil.getCompany(userInfo);
    company.managerIds = objectPath.get(company, 'managerIds', []);

    return <div className="app-container-wrap">
      <PageHeading icon={<PeopleIcon />} title={TranslatorUtil.t("Users")} />


      {company === null || listDataLoading ? <ComponentLoading /> : <React.Fragment>
        <Box pt={1}>
          <Tabs value={tabId} onChange={this.handleChangeTab} indicatorColor="primary">
            <Tab label={TranslatorUtil.t("Invitations")} />
            <Tab label={TranslatorUtil.t("User accounts")} />
          </Tabs>
        </Box>
        <Box pt={4}>
            {tabId === 0 ? <InvitationForm proceedSubmit={this.handleSubmit} processing={invitationsInProcess} /> : null}
            {tabId === 1 ? <Box textAlign="right" m={2}>
              <FormControlLabel
                    control={
                      <Switch
                        checked={excludeDisabledUsers}
                        onChange={() => this.setState({excludeDisabledUsers: !excludeDisabledUsers})}
                        color="primary"
                      />
                    }
                    label="Exclude disabled users"
                  />

            </Box> : null}
            {tabId === 0 || tabId === 1 ? <AppSortableTable hasActions={true} rows={this.getTableRows()} headers={this.getTableHeaders()} actionHeader={this.getActionHeader()} emptyMessage={TranslatorUtil.t("Let's invite people to share carpark with!")} defaultSortBy="email" /> : null}        
        </Box>
      </React.Fragment>
      }

      {

        showConfirmation ?
          invitationToRemove ? <ConfirmDialog title={confirmTitle} message={confirmMessage} onCancel={this.confirmOnInvitationCancel} onConfirm={this.confirmOnInvitationConfirm} /> :
            userToDisable ? <ConfirmDialog title={confirmTitle} message={confirmMessage} onCancel={this.confirmOnUserCancel} onConfirm={this.confirmOnUserConfirm} />
              : null : null
      }
    </div>
  }
}



function mapStateToProps(state) {
  return {
    companyProfiles: state.companyProfiles,
    companyInvitations: state.companyInvitations,
    companyParks: state.companyParks,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...profileActions,
        ...invitationActions,
        ...parkActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyUsers)
));
