import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as profileActions from "../../actions/profile";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";
import ValidationUtil from "../../utils/ValidationUtil";
import { firebaseFunctions } from "../../firebase";
import UserUtil from "../../shared/utils/UserUtil";

class DisputeHelpDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberPlate: "",
      message: "",
      processing: false,
    };
  }

  handleSave = () => {
    const { userInfo, enqueueSnackbar } = this.props;
    const { numberPlate, message } = this.state;
    console.log("numberPlate", numberPlate);
    console.log("message", message);
    const postDisputeComment =
      firebaseFunctions.httpsCallable("postDisputeComment");
    this.setState({ processing: true });
    postDisputeComment({
      uid: UserUtil.getId(userInfo),
      companyId: UserUtil.getCompanyId(userInfo),
      comment: message,
      plateNumber: numberPlate,
    }).then((response) => {
      enqueueSnackbar(TranslatorUtil.t("Your comment was submitted"), {
        variant: "success",
      });

      this.props.onClose();
    }).finally(() => {
      this.setState({ processing: false });
    });
  };

  handleClose = () => {
    this.setState({ numberPlate: "", message: "" });
    this.props.onClose();
  };

  handleChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    this.setState({ numberPlate: value.trim().toUpperCase() });
  };

  render() {
    const { numberPlate, message } = this.state;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="simple-dialog-title"
        open={true}
      >
        <DialogTitle id="simple-dialog-title" disableTypography={true}>
          <Typography variant="h5" color="primary">
            {TranslatorUtil.t("Someone in your park?")}
          </Typography>
        </DialogTitle>
        <div>
          <Box px={2} py={0}>
            <Box pl={1} className="text-muted">
              {TranslatorUtil.t(
                "Enter the number plate of the car in your park along with a detailed comment and  send it to the car owner and your parking administrator."
              )}
            </Box>
          </Box>
          <Box px={3} py={2}>
            <FormGroup>
              <TextField
                style={{ maxWidth: "200px" }}
                label={TranslatorUtil.t("Number plate")}
                placeholder={TranslatorUtil.t("optinal")}
                inputProps={{ maxLength: 10 }}
                onInput={(e) => this.handleChange(e)}
                value={numberPlate}
                error={!ValidationUtil.isPlateNumberValid(numberPlate)}
                helperText={
                  !ValidationUtil.isPlateNumberValid(numberPlate) &&
                  TranslatorUtil.t(
                    "Please enter valid number plate (3-10 characters)"
                  )
                }
              />
            </FormGroup>
            <Box pt={2}>
              <FormGroup>
                <TextField
                  style={{ maxWidth: "400px" }}
                  label={TranslatorUtil.t("Comment")}
                  multiline
                  onInput={(e) => this.setState({ message: e.target.value })}
                  value={message}
                  // error={numberPlate2Error}
                  // helperText={
                  //   numberPlate2Error &&
                  //   TranslatorUtil.t(
                  //     "Please enter valid number plate (3-10 characters)"
                  //   )
                  // }
                />
              </FormGroup>
            </Box>
          </Box>
          <Box p={2}>
            <Grid
              spacing={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="text" onClick={this.handleClose} disabled={this.state.processing}>
                {TranslatorUtil.t("Cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={(message.length < 1 || message.length > 200) || this.state.processing}
                startIcon={this.state.processing ? <CircularProgress size={10} className="progress" /> : null }
                onClick={this.handleSave}
              >
                {TranslatorUtil.t("Send")}
              </Button>
            </Grid>
          </Box>
        </div>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...profileActions,
      },
      dispatch
    ),
  };
}

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(DisputeHelpDialog)
);
