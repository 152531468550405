//this is super important because of circular dependency to go after export
import objectPath from "object-path";
import DateUtil from "./general/DateUtil";
import FormatterUtil from "./general/FormatterUtil";
import Util from "./general/Util";
import ArrayUtil from "./general/ArrayUtil";
import UserUtil from "./UserUtil";
import CompanyUtil from "./CompanyUtil";
import RepeatingRosterUtil from "./RepeatingRosterUtil";
import LogUtil from "./general/LogUtil";

/** 
 * Class helper to have all park and booking logic in one place 
 * this shared folder is also watched and copied to 
 * functions folder to be reused there
*/
class ParkUtil {

  /**
   * Check if park is booked for given date by park states and ownership
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate - object with date property in moment object 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {boolean} 
   */
  static isBooked(park, selectedDate, parkStates, parkOwnerships, users) {
    return ParkUtil.getBookedBy(park, selectedDate, parkStates, parkOwnerships, users) !== null;
  }

  /**
   * Return profile object that has park booked for given date
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {profile} profile - user/profile
   */
  static getBookedBy(park, selectedDate, parkStates, parkOwnerships, users) {
    var owner = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    var parkStatusWithOwner = ParkUtil.getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    var parkStatusWithoutOwner = ParkUtil.getParkStatusWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    if (owner) {
      if (parkStatusWithoutOwner) { // higher priority than owner
        return users.find(x => ParkUtil.isUserHolderOfParkState(parkStatusWithoutOwner, x));
      } else if (parkStatusWithOwner === null) {
        return owner;
      }
    } else if (parkStatusWithoutOwner) {
      return users.find(x => ParkUtil.isUserHolderOfParkState(parkStatusWithoutOwner, x));
    }
    return null;
  }

  /**
   * Filter given array of days to days that user has park booked
   *
   * @param {day[]} days - array of days object with date property in moment 
   * @param {park[]} parks array from firestore
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {day[]} days - filtered array of days
   */
  static getBookedDaysByUser(days, parks, parkStates, parkOwnerships, users, user) {
    var totalParksBooked = ParkUtil.getBookedParksByUser(days, parks, parkStates, parkOwnerships, users, user);
    return days.filter(day => totalParksBooked.filter(tpb => DateUtil.isSameDay(tpb.day.date,day.date)).length > 0);
  }

  /**
   * Check if user has park for given day
   *
   * @param {day} day - array of days object with date property in moment 
   * @param {park[]} parks array from firestore
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {day[]} days - filtered array of days
   */
   static hasUserParkForDay(day, parks, parkStates, parkOwnerships, users, user) {
    var totalParksBooked = ParkUtil.getBookedParksByUser([day], parks, parkStates, parkOwnerships, users, user);
    return totalParksBooked.length > 0;
  }

  /**
   * Returns array of {park,day} objects booked by user in given days
   *
   * @param {day[]} days - array of days object with date property in moment 
   * @param {park[]} parks array from firestore
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @param {profile} profile - user/profile of user to check 
   * @return {array} bookedParksDays - array of booked parks {park,day} objects
   */
  static getBookedParksByUser(days, parks, parkStates, parkOwnerships, users, user) {
    var totalBooked = [];
    days.forEach(d => {
      parks.forEach(p => {
        var bookedBy = ParkUtil.getBookedBy(p, d, parkStates, parkOwnerships, users);
        if (UserUtil.compareUsers(bookedBy, user)) {
          totalBooked.push({ park: p, day: d });
        }
      });
    });
    return totalBooked;
  }

    /**
   * Returns array of {park,day,user} objects booked by user in given days
   *
   * @param {day[]} days - array of days object with date property in moment 
   * @param {park[]} parks array from firestore
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {array} bookedParksDays - array of booked parks {park,day} objects
   */
     static getBookingData(days, parks, parkStates, parkOwnerships, users) {
      var bookingData = [];
      days.forEach(d => {
        parks.forEach(p => {
          var filteredParkStates = ParkUtil.getParkStatusesByParkAndDate(p, d, parkStates);
          var bookedBy = ParkUtil.getBookedBy(p, d, filteredParkStates, parkOwnerships, users);
          if (bookedBy) {
            bookingData.push({ park: p, day: d, user: bookedBy });
          }
        });
      });
      return bookingData;
    }

  /**
   * Returns profile of user who proceeded park state change
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {profile} profile - user/profile
   */
  static getProceededBy(park, selectedDate, parkStates, parkOwnerships, users) {
    var parkStatusWithoutOwner = ParkUtil.getParkStatusWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    var parkStatusWithOwner = ParkUtil.getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    if (parkStatusWithoutOwner) { // higher priority than owner
      return users.find(x => objectPath.get(x, 'id', null) !== null && x.id === objectPath.get(parkStatusWithoutOwner, 'proceededBy', null));
    }
    if (parkStatusWithOwner) {
      return users.find(x => objectPath.get(x, 'id', null) !== null && x.id === objectPath.get(parkStatusWithOwner, 'proceededBy', null));
    }
    return null;
  }


  /**
   * Returns array of park states filtered by park
   *
   * @param {park} park - object from firestore
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @return {parkState[]} parkStates - filtered park states by selected date 
   */
   static getParkStatusesByPark(park, parkStates) {
    return parkStates.filter(x => x.parkId === park.id);
  }

  /**
   * Returns array of park states filtered by selected date
   *
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @return {parkState[]} parkStates - filtered park states by selected date 
   */
   static getParkStatusesByDate(selectedDate, parkStates) {
    return parkStates.filter(x => x.date === DateUtil.dbFormat(selectedDate.date));
  }

  /**
   * Returns array of park states filtered by park and selected date
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @return {parkState[]} parkStates - filtered park states by park and selected date 
   */
  static getParkStatusesByParkAndDate(park, selectedDate, parkStates) {
    return parkStates.filter(x => x.parkId === park.id && x.date === DateUtil.dbFormat(selectedDate.date));
  }

  /**
   * Returns park state that of given park for given day if owner unlocked park (lock type === false)
   * only newest record return if more than one matches conditions
   * 
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {parkState} parkState - park state firestore object
   */
  static getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users) {
    var dayStatuses = ParkUtil.getParkStatusesByParkAndDate(park, selectedDate, parkStates);

    var parkOwnerByDate = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    return parkOwnerByDate && !FormatterUtil.isEmpty(dayStatuses) && !FormatterUtil.isEmpty(dayStatuses.filter(x => x.lockType === false && ParkUtil.isUserHolderOfParkState(x, parkOwnerByDate))) ?
      dayStatuses.sort((a, b) => b.updatedUnix - a.updatedUnix)
        .find(x => x.lockType === false && ParkUtil.isUserHolderOfParkState(x, parkOwnerByDate))
      :
      null;
  }

  /**
   * Returns park state that of given park for given day if booked by none owner (lock type === true)
   * only oldest record return if more than one matches conditions
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {parkState} parkState - park state firestore object
   */
  static getParkStatusWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users) {
    var dayStatuses = ParkUtil.getParkStatusesByParkAndDate(park, selectedDate, parkStates);

    var parkOwnerByDate = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    return !FormatterUtil.isEmpty(dayStatuses)  && !FormatterUtil.isEmpty(dayStatuses.filter(x => x.lockType === true && !ParkUtil.isUserHolderOfParkState(x, parkOwnerByDate))) ? dayStatuses
      .sort((a, b) => a.updatedUnix - b.updatedUnix)
      .find(x => x.lockType === true && !ParkUtil.isUserHolderOfParkState(x, parkOwnerByDate)) : null;
  }

  /**
   * Returns array of park states that of given park for given day if booked by none owner (lock type === true)
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {parkState[]} parkStates - array of parks states
   */
  static getParkStatusesWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users) {
    var dayStatuses = ParkUtil.getParkStatusesByParkAndDate(park, selectedDate, parkStates);
    var parkOwnerByDate = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    return !FormatterUtil.isEmpty(dayStatuses) ?
      dayStatuses.filter(x => x.lockType === true && !ParkUtil.isUserHolderOfParkState(x, parkOwnerByDate))
      :
      [];
  }

  /**
   * Returns current park owner so no ownership history data needed.
   *
   * @param {park} park - object from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {profile} profile - user/profile
   */
  static getCurrentParkOwner(park, users) {
    let ownerId = objectPath.get(park, 'ownerId', null);
    let ownerInvitationEmail = objectPath.get(park, 'invitedOwnerEmail', null);
    let companyId = objectPath.get(park, 'companyId', null);
    let owner = users.find(x => UserUtil.isProfileByIdOrEmail(x, ownerInvitationEmail, ownerId));
    if (!owner && ownerInvitationEmail) {
      owner = UserUtil.getTempUserInvitationByEmail(ownerInvitationEmail,companyId);
    }
    return owner;
  }

  /**
   * Returns current park owner by ownership
   *
   * @param {ownership} ownership - from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {profile} profile - user/profile
   */
  static getParkOwnerByOwnership(ownership, users) {
    let ownerId = objectPath.get(ownership, 'ownerId', null);
    let ownerInvitationEmail = objectPath.get(ownership, 'invitedOwnerEmail', null);
    let companyId = objectPath.get(ownership, 'companyId', null);
    let owner = users.find(x => UserUtil.isProfileByIdOrEmail(x, ownerInvitationEmail, ownerId));
    if (!owner && ownerInvitationEmail) {
      owner = UserUtil.getTempUserInvitationByEmail(ownerInvitationEmail,companyId);
    }
    return Util.isEmpty(owner) ? null : owner;
  }

  /**
   * Returns park owner by given date
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {profile} profile - user/profile
   */
  static getParkOwnerByDate(park, selectedDate, parkOwnerships, users) {
    var validParkOwnershipByDate = ParkUtil.getValidParkOwnershipByDate(park, selectedDate, parkOwnerships);
    return ParkUtil.getParkOwnerByOwnership(
      validParkOwnershipByDate,
      users
    );
  }

  /**
   * Returns the latest park ownership object
   *
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {number} parkID - park ID
   * @return {profile} profile - user/profile
   */
  static getLastParkOwnership(parkOwnerships, parkId) {
    var parkOwnershipHistory = Array.isArray(parkOwnerships) ? parkOwnerships.filter(x => x.parkId === parkId) : [];
    return !FormatterUtil.isEmpty(parkOwnershipHistory) ? parkOwnershipHistory.sort((a, b) => a.startedUnix - b.startedUnix)[(parkOwnershipHistory.length - 1)] : null;
  }

  /**
   * Returns the latest park ownership object
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @return {profile} profile - user/profile
   */
  static getValidParkOwnershipByDate(park, selectedDate, parkOwnerships) {
    //if current or future just return park data as ownership
    if(DateUtil.isFuture(selectedDate.date.endOf('day'))){
      return {
        companyId : park.companyId,
        invitedOwnerEmail : park.invitedOwnerEmail,
        ownerId: park.ownerId,
        parkId: park.id,
        startedUnix: selectedDate.date.startOf('day').valueOf()
      }
    }
    var selectedDateEndUnix = selectedDate.date.endOf('day').valueOf();
    var validOwnerships = parkOwnerships
      .sort((a, b) => a.startedUnix - b.startedUnix)
      .filter((ownership, index) =>
        ownership.startedUnix <= selectedDateEndUnix
        && ownership.parkId === park.id
      );
    var lastValidOwnership = !FormatterUtil.isEmpty(validOwnerships) ? validOwnerships[(validOwnerships.length - 1)] : null;
    var isLastOwnershipNotEnded = lastValidOwnership && (!objectPath.has(lastValidOwnership, 'endedUnix') || (objectPath.has(lastValidOwnership, 'endedUnix') && lastValidOwnership.endedUnix >= selectedDateEndUnix));
    return isLastOwnershipNotEnded ? lastValidOwnership : null;
  }

  
  /**
   * Returns park state object that will be sent to be stored in firestore
   * to book the park
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {profile} bookFor - user/profile of user that the park will be booked for
   * @param {userInfo} doneBy - userInfo see UserUtil.getUserInfoObject
   * @param {boolean} bulkAction - done by bulk actions
   * @return {parkState} parkState - parkState object
   */
  static getBookActionObject(park, selectedDate, bookFor, doneBy, bulkAction = false) {
    var selectedUser = bookFor ? bookFor : UserUtil.getProfile(doneBy);
    return {
      companyId: objectPath.get(selectedUser, 'companyId', null),
      userId: objectPath.get(selectedUser, 'id', null),
      proceededBy: UserUtil.getId(doneBy),
      date: DateUtil.dbFormat(selectedDate.date),
      day: DateUtil.dbFormatDay(selectedDate.date),
      month: DateUtil.dbFormatMonth(selectedDate.date),
      year: DateUtil.dbFormatYear(selectedDate.date),
      parkId: park.id,
      lockType: true,
      parkOwnerId: park.ownerId ? park.ownerId : null,
      invitedOwnerEmail: park.invitedOwnerEmail ? park.invitedOwnerEmail : null,
      updatedUnix: DateUtil.getServerTime(),
      dateUnix: selectedDate.date.valueOf()
    }
  }

  /**
   * Returns park state object that will be sent to be stored in firestore
   * to book the park
   *
   * @param {string} companyId - id of company
   * @param {repeatingRosterSetting} repeatingRosterSetting - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @return {parkState} parkState - parkState object
   */
  static getBookActionObjectFromRepeatingRoster(companyId, repeatingRosterSetting, selectedDate) {
    return {
      companyId: companyId,
      userId: objectPath.get(repeatingRosterSetting, 'userId', null),
      proceededBy: null,
      date: DateUtil.dbFormat(selectedDate.date),
      day: DateUtil.dbFormatDay(selectedDate.date),
      month: DateUtil.dbFormatMonth(selectedDate.date),
      year: DateUtil.dbFormatYear(selectedDate.date),
      parkId: objectPath.get(repeatingRosterSetting, 'parkId', null),
      lockType: true,
      parkOwnerId: null,
      invitedOwnerEmail: objectPath.get(repeatingRosterSetting, 'invitedOwnerEmail', null),
      updatedUnix: DateUtil.getServerTime(),
      dateUnix: selectedDate.date.valueOf()
    }
  }

  /**
   * Book park for given date and user
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate object with date property in moment object
   * @param {profile} selectedUser - user/profile that park will be booked for
   * @param {userInfo} doneBy - userInfo see UserUtil.getUserInfoObject
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {any} actions - object with redux actions to call firestore endpoint
   * @param {boolean} bulkAction - done by bulk actions
   * @return {promise} promiseAction
   */
  static bookPark(park, selectedDate, selectedUser, doneBy, parkOwnerships, users, parkStates, actions, bulkAction = false) {
    selectedUser = selectedUser ? selectedUser : UserUtil.getProfile(doneBy);
    var parkOwner = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    var bookStateByOwner = ParkUtil.getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    //LogUtil.devOnly('bookPark', { parkOwner, selectedUser });
    if (UserUtil.compareUsers(parkOwner, selectedUser) && bookStateByOwner !== null) {
      return actions.deleteParkStateById(bookStateByOwner.id);
    }
    return actions.postParkState(ParkUtil.getBookActionObject(park, selectedDate, selectedUser, doneBy, bulkAction));
  }


  
  /**
   * Returns park state object that will be sent to be stored in firestore
   * to unlock the park
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate  - object with date property in moment 
   * @param {profile} bookFor - user/profile of user that the park will be booked for
   * @param {boolean} bulkAction - done by bulk actions
   * @return {parkState} parkState - parkState object
   */
  static getUnlockParkObject(park, selectedDate, selectedUser, userInfo, bulkAction = false) {
    selectedUser = selectedUser ? selectedUser : UserUtil.getProfile(userInfo);
    LogUtil.devOnly('getUnlockParkObject', { selectedUser });
    return {
      companyId: objectPath.get(selectedUser, 'companyId', null),
      userId: objectPath.get(selectedUser, 'id', null),
      proceededBy: UserUtil.getId(userInfo),
      date: DateUtil.dbFormat(selectedDate.date),
      day: DateUtil.dbFormatDay(selectedDate.date),
      month: DateUtil.dbFormatMonth(selectedDate.date),
      year: DateUtil.dbFormatYear(selectedDate.date),
      parkId: park.id,
      lockType: false,
      parkOwnerId: park.ownerId ? park.ownerId : null,
      invitedOwnerEmail: park.invitedOwnerEmail ? park.invitedOwnerEmail : null,
      updatedUnix: DateUtil.getServerTime(),
      dateUnix: selectedDate.date.valueOf()
    };
  }


  /**
   * Unlock park for given date and user
   *
   * @param {park} park - object from firestore
   * @param {selectedDate} selectedDate object with date property in moment object
   * @param {profile} selectedUser - user/profile that park will be booked for
   * @param {userInfo} doneBy - userInfo see UserUtil.getUserInfoObject
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {any} actions - object with redux actions to call firestore endpoint
   * @param {boolean} bulkAction - done by bulk actions
   * @return {promise} promiseAction
   */
  static unlockPark(park, selectedDate, doneBy, parkOwnerships, users, parkStates, actions, bulkAction = false) {
    var parkOwner = ParkUtil.getParkOwnerByDate(park, selectedDate, parkOwnerships, users);
    //because of offline double booking bug we need to get array to unlock parks without owner lock type
    var bookStatesByNotOwner = ParkUtil.getParkStatusesWithoutOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);
    var bookStateByOwner = ParkUtil.getParkStatusWithOwnerByDate(park, selectedDate, parkStates, parkOwnerships, users);

    LogUtil.devOnly('unlockPark', { parkOwner, doneBy, bookStatesByNotOwner });

    //remove park state for non Owner
    if (ArrayUtil.isNonEmptyArray(bookStatesByNotOwner)) {
      return Promise.all(
        bookStatesByNotOwner.map(
          (bookStateByNotOwner) => actions.deleteParkStateById(bookStateByNotOwner.id)
        )
      );
    }
    //if unlock for owner 
    //add unlock state
    else if (bookStateByOwner === null) {
      return actions.postParkState(ParkUtil.getUnlockParkObject(park, selectedDate, parkOwner, doneBy, bulkAction));
    } 
    return Promise.resolve();
  }



  /**
   * Check if user is current owner of the park by id or email (invitation)
   *
   * @param {park} park - object from firestore
   * @param {string} userId - user id
   * @param {string} email - email
   * @return {boolean}
   */
  static isUserCurrentOwner(park, userId, email) {
    return ParkUtil.isUserCurrentOwnerById(park, userId) || ParkUtil.isUserCurrentOwnerByInvitationEmail(park, email);
  }

  /**
   * Check if user is current owner of the park by email (invitation)
   * To do fo full check use method isUserCurrentOwner
   *
   * @param {park} park - object from firestore
   * @param {string} email - email
   * @return {boolean}
   */
  static isUserCurrentOwnerByInvitationEmail(park, email) {
    return Util.compareCaseInsensitive(objectPath.get(park, 'invitedOwnerEmail', ''),email);
  }

  /**
   * Check if user is current owner of the park by id
   * To do fo full check use method isUserCurrentOwner
   *
   * @param {park} park - object from firestore
   * @param {string} userId - user id
   * @return {boolean}
   */
  static isUserCurrentOwnerById(park, userId) {
    return objectPath.get(park, 'ownerId', '') === userId;
  }

  /**
   * Check if owner of the park has been changed by comparing new and old values
   *
   * @param {park} newPark - modified park
   * @param {park} oldPark - before save park from firestore
   * @return {boolean}
   */
  static hasNewOwner(newPark, oldPark) {
    var lastParkOwnerId = objectPath.get(oldPark, 'ownerId', null);
    var lastParkInvitedOwnerEmail = String(objectPath.get(oldPark, 'invitedOwnerEmail', '')).toLocaleLowerCase();
    var newParkOwnerId = objectPath.get(newPark, 'ownerId', null);
    var newParkInvitedOwnerEmail = String(objectPath.get(newPark, 'invitedOwnerEmail', '')).toLocaleLowerCase();

    return (newParkOwnerId && newParkOwnerId !== lastParkOwnerId) || (newParkInvitedOwnerEmail && newParkInvitedOwnerEmail !== lastParkInvitedOwnerEmail);
  }

  /**
   * Check if park has some user assigned as owner
   *
   * @param {park} park - park
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {boolean}
   */
  static hasOwnerAssigned(park, profiles) {
    var parkOwnerId = objectPath.get(park, 'ownerId', '');
    var parkInvitedOwnerEmail = String(objectPath.get(park, 'invitedOwnerEmail', '')).toLocaleLowerCase();

    var isOwned = (parkOwnerId  && profiles.map(p => p.id).includes(parkOwnerId)) || (parkInvitedOwnerEmail && parkInvitedOwnerEmail.indexOf('@') > 1);

    return isOwned;
  }

  static isParkBookingScheduledByRoster(park, selectedDate, userInfo, repeatingRosterSetting) {
    if(RepeatingRosterUtil.getScheduledSettingByParkAndDate(park, selectedDate, repeatingRosterSetting) !== null){
      return true;
    }
    
    return false;
  }

  /**
   * Check if had owner and does not have a current owner
   *
   * @param {park} newPark - modified park
   * @param {park} oldPark - before save park from firestore
   * @return {boolean}
   */
  static hadOwnerAndDoesNotHaveNewOne(newPark, oldPark) {
    var lastParkOwnerId = objectPath.get(oldPark, 'ownerId', null);
    var lastParkInvitedOwnerEmail = objectPath.get(oldPark, 'invitedOwnerEmail', null);
    var newParkOwnerId = objectPath.get(newPark, 'ownerId', null);
    var newParkInvitedOwnerEmail = objectPath.get(newPark, 'invitedOwnerEmail', null);
    return (lastParkOwnerId || lastParkInvitedOwnerEmail) && (
      FormatterUtil.isEmpty(newParkOwnerId) && FormatterUtil.isEmpty(newParkInvitedOwnerEmail)
    );
  }

  /**
   * Check if park state is related to given user / profile
   *
   * @param {parkState} parkState - from firestore
   * @param {profile} profile - user/profile from firestore
   * @return {boolean}
   */
  static isUserHolderOfParkState(parkState, user) {
    return user && parkState &&
      (
        (objectPath.get(parkState, 'userId', null) !== null && objectPath.get(parkState, 'userId', null) === objectPath.get(user, 'id', null))
        ||
        ( 
          // invitation only account cannot lock so checking only for unlock state
          parkState.lockType === false && Util.compareCaseInsensitive(objectPath.get(parkState, 'invitedOwnerEmail', null),objectPath.get(user, 'email', null)) 
        ) 
      );
  }

  /**
   * Check if park state is related to given user / profile
   *
   * @param {park[]} parks - array of parks from firestore
   * @param {selectedDate} selectedDate - object with date property in moment object 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject
   * @param {parkState[]} parkStates - array of parkStates from firestore
   * @param {parkOwnership[]} parkOwnerships array of parkOwnership from firestore
   * @param {profile[]} profiles - array of profiles from firestore may include invitations
   * @return {boolean}
   */
  static isBookingDisabledForCurrentUser(companyParks, selectedDate, userInfo, parkStates, parkOwnerships, users) {
    if(UserUtil.isAtLeastCompanyManager(userInfo)){
        return false;
    }
    var company = UserUtil.getCompany(userInfo);
    var weekDays = DateUtil.getDaysOfWeeks(selectedDate.date, 1, true, CompanyUtil.includeWeekend(company));
    var maxParksPerUser = CompanyUtil.getMaxParksPerUserPerPeriod(company);
    var bookedByUser = ParkUtil.getBookedParksByUser(weekDays, companyParks, parkStates, parkOwnerships, users, UserUtil.getProfile(userInfo));
    return (maxParksPerUser - 1) < bookedByUser.length;
  }


  /**
   * Check if normal user can see who took the park
   * 
   * @param {profile} bookedBy - user/profile of user that has the booking
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static hideBookedBy(bookedBy, userInfo) {
    if(CompanyUtil.hideBookedByForNormalUsers(UserUtil.getCompany(userInfo)) && bookedBy !== null && !UserUtil.isAtLeastCompanyManager(userInfo)){
      return !UserUtil.compareProfilesByEmail(userInfo.profile,bookedBy);
    }
    return false;
  }

  /**
   * Check if normal user can see more info about park state
   * 
   * @param {userInfo} userInfo - see UserUtil.getUserInfoObject(profile,company,firebaseAuth = null)
   * @return {boolean} 
   */
  static showBookMoreInfoButton(userInfo) {
    return !(CompanyUtil.hideBookedByForNormalUsers(UserUtil.getCompany(userInfo)) && !UserUtil.isAtLeastCompanyManager(userInfo));
  }

  /**
   * get park state id to prevent conflict
   *
   * @param {parkState} parkState - array of parkStates from firestore
   * @return {string} 
   */
  static getParkStateFirestoreDocId(parkState) {
    var sections = [];
    sections.push(objectPath.get(parkState,'parkId'));
    sections.push(objectPath.get(parkState,'date'));
    sections.push(objectPath.get(parkState,'lockType',true) ? '1' : '0');
    return sections.join('__');
  }


}


export default ParkUtil;





