import React from "react";
import { Field, reduxForm } from "redux-form";
import * as formNames from "../../../constants/forms";
import {
  TextInput,
} from "../../../components/form/inputs";


import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box } from "@material-ui/core";
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import { Alert } from '@material-ui/lab';

import UserUtil from '../../../shared/utils/UserUtil';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';

const formName = formNames.PROFILE_FORM;

const ProfileForm = props => {

  const {
    handleSubmit,
    pristine,
    submitting,
    userInfo,
    proceedSubmit
  } = props;



  const disableSubmit = pristine || submitting;
  const submitButton = <Button variant="contained" color="primary" type="submit" size="large" disabled={disableSubmit}>{TranslatorUtil.t("Save")}</Button>;

  return (
    <Box pb={2} pt={1}>
      <form onSubmit={handleSubmit(values => {
        let editableValues = {};
        if (values.displayName !== userInfo.displayName) editableValues.displayName = values.displayName;
        if (values.phone !== userInfo.phone) editableValues.phone = values.phone;
        if (values.info !== userInfo.info) editableValues.info = values.info;
        
        return proceedSubmit(editableValues)
      })}>

        <div className="maxWidthPart">
          <Grid container spacing={2}>


            <Grid item xs={12}>

              <Alert icon={
                UserUtil.isCompanyAdmin(userInfo) ? <BusinessOutlinedIcon /> : (UserUtil.isCompanyManager(userInfo) ? <BusinessCenterOutlinedIcon /> : <AccountBoxOutlinedIcon />)
              } severity="info">
                {TranslatorUtil.t(UserUtil.getUserTypeName(userInfo))}
              </Alert>

            </Grid>





            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <Field name="email" component={TextInput} label={TranslatorUtil.t("Email")} readOnly disabled />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Field name="displayName" component={TextInput} label={TranslatorUtil.t("Display name")} required />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Field name="phone" component={TextInput} label={TranslatorUtil.t("Phone")} />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <Field name="info" component={TextInput} label={TranslatorUtil.t("Info")} multiline />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box pt={2} display="flex" flexDirection="row-reverse">
                {submitButton}
              </Box>
            </Grid>



          </Grid>
        </div>
      </form>
    </Box>
  );

}

export default reduxForm({
  form: formName,
  validate,
})(ProfileForm);
