import React from "react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import * as formNames from "../../../constants/forms";
import {
  TextInput,
  SelectInput,
  CheckboxInput
} from "../../../components/form/inputs";
import { withSnackbar } from "notistack";


import validate from "./validate";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box, MenuItem, Link, Paper, Typography, LinearProgress } from "@material-ui/core";
import AlertDialog from '../../../components/AlertDialog';

import timezones from '../../../shared/constants/timezones';
import latestTimeToUnlock from '../../../shared/constants/latestTimeToUnlock';
import constants from '../../../shared/constants/constants';
import plans from '../../../shared/constants/plans';
import TranslatorUtil from '../../../shared/utils/general/TranslatorUtil';
import EmojiTransportation from '@material-ui/icons/EmojiTransportation';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { firebaseStorage } from '../../../firebase';
import objectPath from "object-path";
import { RemoveCircleOutline } from "@material-ui/icons";
import disableCurrentDayAt from "../../../shared/constants/disableCurrentDayAtTime";
import currencies from "../../../shared/constants/currencies";
import langs from "../../../shared/constants/langs";


const formName = formNames.COMPANY_PROFILE_FORM;

export class CompanySettingForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAdminDialog: false,
      fileUploadProgress: 0,
      fileUrl: null,
    }
  }


  componentDidUpdate(prevProps) {
    const { initialValues, change, selectedPlan, selectedUserId, optionsData } = this.props;
    if (selectedPlan !== prevProps.selectedPlan) {
      if (selectedPlan === plans.PAY_AS_YOU_GO && initialValues.plan === selectedPlan) {
        change(formName, 'pricePerPark', initialValues.pricePerPark);
      } else {
        change(formName, 'pricePerPark', optionsData.plans.find(x => x.value === selectedPlan).pricePerPark);
      }
      change(formName, 'parksLimit', optionsData.plans.find(x => x.value === selectedPlan).parksLimit);
    }
    if (selectedUserId !== prevProps.selectedUserId && selectedUserId !== initialValues.userId) {
      this.setState({ showAdminDialog: true });
    }
    
    const companyMapPath = objectPath.get(initialValues,'mapPath',null);
    // Get the download URL
    if(companyMapPath){
      firebaseStorage.ref()
      .child(companyMapPath)
      .getDownloadURL()
      .then((downloadURL) => {
        this.setState({
          fileUrl : downloadURL
        });
      });
    }
    
  }

  getParkLayoutFilePath = () => {
    
    return 'companies/'+this.getCompanyId()+'/parking-layout';
  }

  getCompanyId = () => {
    const { initialValues } = this.props;
    return objectPath.get(initialValues,'companyId',null);
  }

  parkMapChanged = (e) => {
    const { updateCompanyMap, enqueueSnackbar } = this.props;
    const file = e.target.files[0];
    const maxFileSize = 20480; //20MB 20480
    const fileSize = file.size ? Math.round((file.size / 1024)) : 0;
    if(fileSize < maxFileSize){
      const storageRef = firebaseStorage.ref().child(this.getParkLayoutFilePath());
      const uploadTask = storageRef.put(file);

      uploadTask.on("state_changed",
        (snapshot) => {
          var progress = ((snapshot.bytesTransferred / snapshot.totalBytes) * 100) - 0.1;
          this.setState({
            fileUploadProgress : progress
          });
        },
        (error) => {
          this.setState({
            fileUploadProgress : 0,
          });
          alert(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => updateCompanyMap(
              this.getParkLayoutFilePath()
            ).then(()=>this.setState({
              fileUrl : downloadURL,
              fileUploadProgress : 100,
            })));
        }
      );
    } else {
        enqueueSnackbar(TranslatorUtil.t("File too big, please select a file less than 20MB."), {
            variant: "error"
        });
    }
    
  }

  removeCompanyMap = () => {
    const { updateCompanyMap } = this.props;

    
    updateCompanyMap(
      null
    ).then(() => this.setState({
      fileUrl : null
    }));
  }


  // changeFieldProgrammatically = () => {
  //   this.props.change(formName, "firstName", "Test");
  // };

  render = () => {
    const {
      handleSubmit,
      pristine,
      submitting,
      proceedSubmit,
      optionsData
    } = this.props;
    const {
      fileUrl,
      fileUploadProgress
    } = this.state;

    const disableSubmit = pristine || submitting;
    const submitButton = <Button variant="contained" color="primary" type="submit" size="large" disabled={disableSubmit}>{TranslatorUtil.t("Save")}</Button>;

    return (
      <Box pb={2} pt={1}>
        {this.state.showAdminDialog ? <AlertDialog
          title={TranslatorUtil.t("Are you sure?")}
          message={TranslatorUtil.t("Changing admin will mean that you cannot manage company anymore and all billings will go to newly selected email. Please be careful because only newly selected user can revert this change!")}
          onClose={() => this.setState({ showAdminDialog: false })} /> : null}
        <form onSubmit={handleSubmit(values => proceedSubmit(values))}>

          <div className="maxWidthPart">
            <Grid container spacing={2}>



              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>

                    <FormGroup>
                      <Field name="companyId" component={TextInput} label={TranslatorUtil.t("Company ID")} readOnly disabled />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="displayName" component={TextInput} label={TranslatorUtil.t("Display name")} />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="userId" component={SelectInput} label={TranslatorUtil.t("Admin")}>
                        {
                          optionsData.profiles.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="managerIds" component={SelectInput} label={TranslatorUtil.t("Managers")} multiple helperText={TranslatorUtil.t("Managers can manage normal users and parks")}>
                        {
                          optionsData.profiles.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="plan" component={SelectInput} label={TranslatorUtil.t("Plan")} readOnly disabled
                      >
                        {
                          optionsData.plans.map(x => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} className="hidden">
                    <FormGroup>
                      <Field name="pricePerPark" type="number" component={TextInput} label={TranslatorUtil.t("Price per park")} readOnly disabled />
                    </FormGroup>
                  </Grid>
                  
                  <Grid item xs={12} className="hidden">
                    <FormGroup>
                      <Field name="parksLimit" type="number" component={TextInput} label={TranslatorUtil.t("Parks limit")} readOnly disabled />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="timezone" component={SelectInput} label={TranslatorUtil.t("Timezone")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_TIMEZONE+". This is used to run day free parks collector to know what time we should send notifications.")}>
                        {
                          timezones.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <Field name="billingCurrency" component={SelectInput} label={TranslatorUtil.t("Billing currency")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_BILLING_CURRENCY+".")}>
                        {
                          currencies.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12} className="hidden">
                    <FormGroup>
                      <Field name="preferredLanguage" component={SelectInput} label={TranslatorUtil.t("Preferred language")} helperText={TranslatorUtil.t("Default is "+constants.DEFAULT_LANG+".")}>
                        {
                          langs.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                        }
                      </Field>
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="hideBookedByForNormalUser" 
                          component={CheckboxInput} 
                          label={TranslatorUtil.t('Hide "booked by" of others for normal users')} helperText={TranslatorUtil.t("By default we show booked by information to all users.")} />
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="parkDisputesHelp" component={CheckboxInput} label={TranslatorUtil.t('Allow users to contact your parking administrator for parking disputes')} helperText={TranslatorUtil.t("Show a help menu item for all users. When someone has a parking dispute like someone has parked in their park, the user can enter a number plate and a comment. The plate number and comment will be emailed to the sender, the plate owner, the admin and managers to resolve. Requires mobile app version 1.07 (October 2024) or greater.")} />
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="includeWeekend" 
                          component={CheckboxInput} 
                          label={TranslatorUtil.t('Allow users to book on weekends')} helperText={TranslatorUtil.t("Include Saturday’s and Sunday’s for users to book. When this setting is off, users will only be able to book Monday to Friday.")} />
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="disableCurrentDayAt" component={SelectInput} label={TranslatorUtil.t("Disable current day at")} helperText={TranslatorUtil.t("By default current day will be disabled at midnight.")}>
                            {
                              disableCurrentDayAt.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                            }
                          </Field>
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>


                  <Grid item xs={12}>
                    <Paper variant="outlined">
                      <Box px={3} py={2}>
                        <FormGroup>
                          <Field name="latestTimeToUnlock" component={SelectInput} label={TranslatorUtil.t("Users cannot unlock park after")} helperText={TranslatorUtil.t("By default users can unlock anytime.")}>
                            {
                              latestTimeToUnlock.map(x => <MenuItem key={x.label} value={x.value}>{x.label}</MenuItem>)
                            }
                          </Field>
                        </FormGroup>
                      </Box>
                    </Paper>
                  </Grid>

                  <Grid item xs={12}>
                  <Paper variant="outlined">
                    <Box px={3} py={2}>
                        <Typography variant="caption">{TranslatorUtil.t(
                          fileUrl ? 'Map of your parking helps users identify parks.'
                          :
                          'Upload a map of your car park to help users find their spot. This can be in PNG/JPG format (recommended) or PDF and must be no larger than 20 MB.')}</Typography>
                    
                  <Box>
                  
                  {
                    parseFloat(fileUploadProgress) > 0 && parseFloat(fileUploadProgress) < 100 ?  <LinearProgress /> 
                    : 
                    fileUrl ?
                    <Link href={fileUrl} variant="body2" target="_blank">
                      {TranslatorUtil.t("Current Map link")} <ArrowRightAltIcon />
                    </Link> : null
                  }

              </Box>
              <Box pt={2}>
                  <Button
                  startIcon={<EmojiTransportation />}
                    variant="contained"
                    component="label"
                  >
                    {fileUrl ? TranslatorUtil.t("Change Map") : TranslatorUtil.t("Upload Map")}
                    
                    <input
                      onChange={this.parkMapChanged}
                      accept="image/jpeg,image/png,application/pdf"
                      type="file"
                      hidden
                    />
                  </Button>


                  
              </Box>

              {fileUrl ? <Box pt={2}>

                  <Button
                  startIcon={<RemoveCircleOutline />}
                    className="text-danger"
                    onClick={this.removeCompanyMap}
                  >
                    {TranslatorUtil.t("Remove Map")}
                    
                  </Button>

                  
              </Box> : null}
              </Box>
              </Paper>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box pt={2} display="flex" flexDirection="row-reverse">
                  {submitButton}
                </Box>

                
                
              </Grid>







            </Grid>
          </div>
        </form>
      </Box>
    );

  }
}



const selector = formValueSelector(formName); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = state => {
  const selectedPlan = selector(state, "plan");
  const selectedPricePerPark = selector(state, "pricePerPark");
  const selectedUserId = selector(state, "userId");
  return {
    selectedPlan,
    selectedPricePerPark,
    selectedUserId
  };
};

// Decorate form with dispatchable actions
const mapDispatchToProps = {
  change
};


// create a redux form, then include the above decorators for the created form to utilize
export default withSnackbar(reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanySettingForm)
));