import constants from "../constants/constants";
import PlanConstants from "../constants/plans";
import DateUtil from "./general/DateUtil";
import ArrayUtil from "./general/ArrayUtil";
import ParkUtil from "./ParkUtil";
  //this is super important because of circular dependency to go after export
import FirestoreUtil from "./general/FirestoreUtil";
class FirestoreQueryUtil {
  
  
    constructor(firestoreApi){
        this.firestoreApi = firestoreApi;
    }


    /**
     * Returns company object
     *
     * @param {string} companyId
     * @return {company} 
     */
     getCompany(companyId){
        return this.firestoreApi.collection('companies')
        .where("companyId", "==", companyId)
        .limit(1)
        .get().then((docs) => {
           return FirestoreUtil.parseFirstItemFromCollectionData(docs)
        });
    }

    /**
     * Returns company object
     *
     * @param {string} companyId
     * @return {company} 
     */
    getCompanyById(companyId){
        return this.firestoreApi.collection('companies')
        .doc(companyId)
        .get().then((doc) => {
           return FirestoreUtil.parseCollectionItemData(doc)
        });
    }

    /**
     * Returns profile object
     *
     * @param {string} id
     * @return {profile} 
     */
     getProfileById(id){
        return this.firestoreApi.collection('profiles')
        .doc(id)
        .get().then((doc) => {
           return FirestoreUtil.parseCollectionItemData(doc)
        });
    }

    /**
     * Returns profile objects
     *
     * @param {Array} ids
     * @return {profiles[]} 
     */
    getProfilesByIds(ids){
        return ArrayUtil.isNonEmptyArray(ids) ? this.firestoreApi.collection('profiles')
        .where('__name__', 'in', ids)
        .get().then((docs) => {
           return FirestoreUtil.parseCollectionData(docs)
        }) : [];
    }

    /**
     * Returns invitation by email
     *
     * @param {string} email
     * @return {invitation} 
     */
     getInvitationByEmail(email){
        return this.firestoreApi.collection('invitations')
        .where("email", "==", email)
        .limit(1)
        .get().then((doc) => {
           return FirestoreUtil.parseFirstItemFromCollectionData(doc)
        });
    }

    /**
     * mark invitation as accepted
     *
     * @param {string} id
     * @return {promise} 
     */
    markInvitationAsAccepted(id){
        return this.firestoreApi.collection('invitations').doc(id).update({
            accepted: true
        });
    }
    

    /**
     * Returns companies that are not on free plan
     *
     * @return {company[]} 
     */
    getBillableCompanies(){
        let validPlans = PlanConstants.options.filter(x => x.value !== PlanConstants.DEFAULT_PLAN).map(x => x.value);
        return this.firestoreApi.collection('companies')
        .where("plan","in",validPlans)
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }

    /**
     * Returns companies that are not on free plan and has billing day as per param
     * @param {number} day
     * @return {company[]} 
     */
     getCompaniesForTodayBilling(day){
        let validPlans = PlanConstants.options.filter(x => x.value !== PlanConstants.DEFAULT_PLAN).map(x => x.value);
        return !Number.isNaN(day) ? this.firestoreApi.collection('companies')
        .where("plan","in",validPlans)
        .where("billingDay",'==',Number(day))
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs)) : [];
    }

    /**
     * Returns billing for company by month and year
     * @param {string} companyId
     * @param {string} month
     * @param {string} year
     * @return {company[]} 
     */
     getCompanyBillingRecordByMonth(companyId,month,year){
        return this.firestoreApi.collection('billingRecords')
        .where("companyId", "==", companyId)
        .where("month", "==", String(month))
        .where("year", "==", String(year))
        .get()
        .then((docs) => FirestoreUtil.parseFirstItemFromCollectionData(docs));
    }

    /**
     * Returns billing by month and year
     * @param {string} month
     * @param {string} year
     * @return {company[]} 
     */
     getBillingRecordsByMonth(month,year){
        return this.firestoreApi.collection('billingRecords')
        .where("month", "==", String(month))
        .where("year", "==", String(year))
        .get()
        .then((docs) => FirestoreUtil.parseCollectionData(docs));
    }

    /**
     * Returns parks states by company and date
     *
     * @param {string} companyId
     * @param {string} date in db format see DateUtil.dbFormat
     * @return {parkState[]} 
     */
     getCompanyDayParkStatuses(companyId, date){
        return this.firestoreApi.collection('parkStates')
        .where("companyId", "==", companyId)
        //.where("lockType", "==", true)
        .where("date", "==", date)
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }

    /**
     * Returns parks by company
     *
     * @param {string} companyId
     * @return {park[]} 
     */
    getCompanyParks(companyId){
        return this.firestoreApi.collection('parks')
        .where("companyId", "==", companyId)
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }

    /**
     * Returns profiles count by company
     *
     * @param {string} companyId
     * @return {number} 
     */
     getCompanyUserProfilesCount(companyId){
        return this.firestoreApi.collection('profiles')
        .where("companyId", "==", companyId)
        .get().then((docs) => docs.size);
    }

    /**
     * Returns parks count by company
     *
     * @param {string} companyId
     * @return {number} 
     */
     getCompanyParksCount(companyId){
        return this.firestoreApi.collection('parks')
        .where("companyId", "==", companyId)
        .get().then((docs) => docs.size);
    }



    /**
     * Returns profiles by company
     *
     * @param {string} companyId
     * @param {boolean} withMessagingTokenOnly default to true
     * @return {profile[]} 
     */
    getCompanyUserProfiles(companyId, withMessagingTokenOnly = true){
        let query = this.firestoreApi.collection('profiles')
        .where("companyId", "==", companyId);
        if(withMessagingTokenOnly){
            query.where("messagingToken", ">", "");//we need tokens to send notifications
        }
        return query.get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }


    /**
     * update company queue priority email
     *
     * @param {company} company
     * @param {string} next email
     * @return {promise} 
     */
    updateCompanyNextPriority(company, next = null){
        if(company && next){
            return this.firestoreApi.collection('companies').doc(company.id).update({
                "nextPriorityUserEmail": next
            });
        }
        return Promise.resolve();
    }


    /**
     * add notification from parkState
     *
     * @param {parkState} parkState
     * @param {bool} priority
     * @return {promise} 
     */
     addNotification(parkState,priority = false){
        return this.firestoreApi.collection('notifications').add({
            ...parkState,
            createdUnix: parkState.updatedUnix + (priority ? 0 : 1),
            sent: false,
            processed: false,
            priority: priority
        });
    }

    /**
     * get unproccessed notificaitions
     *
     * @return {notification[]} 
     */
     getUnproccessedNotifications(){
        return this.firestoreApi.collection('notifications')
        .where("processed", "==", false)
        .where("dateUnix", ">", DateUtil.getYesterdayUnix())
        .orderBy("dateUnix", "asc")
        .orderBy("createdUnix", "asc")
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }


    /**
     * mark notification as proccessed
     *
     * @param {notification} notification
     * @param {boolean} wasSent
     * @return {promise} 
     */
     markNotificationAsProccessed(notification, wasSent = false, result = 0, sendingTo = []){
         var updatedProps = {
            processed: true,
            sent: wasSent,
            result: result,
            updatedUnix: DateUtil.getServerTime(),
        };
        if(wasSent){
            updatedProps.sendingTo = Array.isArray(sendingTo) ? sendingTo.filter(e => typeof e !== 'undefined') : [];
        }
        return this.firestoreApi.collection('notifications').doc(notification.id).update(updatedProps);
    }


    /**
     * update notifications after proccessing
     *
     * @param {notification} notification
     * @param {notification[]} unproccessedNotifications
     * @return {promise} 
     */
     updateNotificationsAfterProcessing(notification, unproccessedNotifications = [],result, sendingTo = []){
        return this.markNotificationAsProccessed(
            notification,
            [
                constants.NOTIFICATION_SENT_TO_ALL_USERS,
                constants.NOTIFICATION_SENT_TO_PRIORITY_USER
            ].includes(result),
            result,
            sendingTo).then(() => 
        Promise.all(unproccessedNotifications.filter(x => 
            //mark similar notification as processed and unsent
            x.companyId === notification.companyId
            &&
            x.parkId === notification.parkId
            &&
            x.date === notification.date
            &&
            x.priority === notification.priority
        )
        .map(unsentNotification => this.markNotificationAsProccessed(unsentNotification,false,result))
        ));
    }


    /**
     * remove invalid tokens
     *
     * @param {string[]} tokens
     * @return {promise} 
     */
    removeMessagingTokens(tokens){
        return Array.isArray(tokens) ? Promise.all(tokens.map(token => this.firestoreApi.collection('profiles')
        .where("messagingToken", "==", token)
        .limit(1)
        .get()
        .then(querySnapshot => {
            if (!querySnapshot.empty) {
                //We know there is one doc in the querySnapshot
                const queryDocumentSnapshot = querySnapshot.docs[0];
                return queryDocumentSnapshot.ref.update({
                    messagingPlatform: null,
                    messagingToken: null
                });
            }
            else {
                return Promise.resolve();
            }
        })
        )) : Promise.resolve()
    }


    /**
     * add record about day check for company
     *
     * @param {string} companyId
     * @param {string} date in db format see DateUtil.dbFormat
     * @return {promise} 
     */
     addDayCheckForCompany(companyId, date){
        return this.firestoreApi.collection('dailyChecks').add({
            companyId: companyId,
            date: date,
            createdUnix: DateUtil.getServerTime()
        });
     }

     /**
     * get list of checked companies for date
     *
     * @param {date} date in db format see DateUtil.dbFormat
     * @return {promise} 
     */
      getCheckedCompaniesByDate(date){
        return this.firestoreApi.collection('dailyChecks')
        .where("date", "==", date)
        .orderBy("createdUnix", "asc")
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
     }
    

     /**
     * get list of park ownerships by company Id
     *
     * @param {string} companyId companyId
     * @return {parkOwnerships[]} 
     */
     getParkOwnershipsByCompanyId(companyId){
        return this.firestoreApi.collection('parkOwnerships')
          .where('companyId', '==', companyId)
          .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
     }

     /**
     * Returns parks states by company and date for whole week
     *
     * @param {string} companyId
     * @param {date} date object with date property in moment object 
     * @param {bool} includeWeekend 
     * @return {parkState[]} 
     */
    getCompanyWholeWeekParkStates(companyId, date, includeWeekend){
        // include one whole week including weekend by default
        var weekDays = DateUtil.getDaysOfWeeks(date.date,1,true, includeWeekend).map(x => DateUtil.dbFormat(x.date));
        return this.firestoreApi.collection('parkStates')
        .where("companyId", "==", companyId)
        .where('date', 'in', weekDays)// here it is one week so limit of 10 items cannot be reached
        .get().then((docs) => FirestoreUtil.parseCollectionData(docs));
    }
     

    /**
     * Returns first lock park state in conflict
     *
     * @param {parkState} parkState
     * @return {parkState} 
     */
    getConflictLockParkState(parkState){
        return parkState && parkState.lockType && parkState.companyId && parkState.date && parkState.parkId ? 
        this.firestoreApi.collection('parkStates')
        .where("companyId", "==", parkState.companyId)
        .where('date', '==', parkState.date)
        .where('parkId', '==', parkState.parkId)
        .where('lockType', '==', true)
        .get().then((docs) => {
            var docsCurrentExcluded = FirestoreUtil.parseCollectionData(docs).filter(x => parkState.id !== x.id);
           return docsCurrentExcluded.length ? docsCurrentExcluded[0] : null;
        }) : Promise.resolve(null);
    }

    /**
     * Returns first lock park state in conflict. This is used when concurrent booking for park owner and noneowner when owner booked first
     *
     * @param {parkState} parkState
     * @return {parkState} 
     */
    getOwnerWantsToBookOwnedParkConflictLockParkState(parkStateToRemove){
        return parkStateToRemove && parkStateToRemove.lockType === false && parkStateToRemove.companyId && parkStateToRemove.date && parkStateToRemove.parkId ? 
        this.firestoreApi.collection('parkStates')
        .where("companyId", "==", parkStateToRemove.companyId)
        .where('date', '==', parkStateToRemove.date)
        .where('parkId', '==', parkStateToRemove.parkId)
        .where('lockType', '==', true)
        .get().then((docs) => {
            var docsCurrentExcluded = FirestoreUtil.parseCollectionData(docs).filter(x => parkStateToRemove.id !== x.id);
           return docsCurrentExcluded.length ? docsCurrentExcluded[0] : null;
        }) : Promise.resolve(null);
    }


    /**
     * get first unprocessed repeating roster
     *
     * @return {repeatingRoster} 
     */
    getFirstUnprocessedRepeatingRoster(){
        return this.firestoreApi.collection('repeatingRoster')
        .where('disabled', '==', false)
        .orderBy("lastRunUnix", "asc")
        .limit(1)
        .get().then((docs) => {
            return FirestoreUtil.parseFirstItemFromCollectionData(docs)
         });
    }

    /**
     * Returns repeating roster object
     *
     * @param {string} companyId
     * @return {company} 
     */
    getRepeatingRosterById(companyId){
        return this.firestoreApi.collection('repeatingRoster')
        .doc(companyId)
        .get().then((doc) => {
           return FirestoreUtil.parseCollectionItemData(doc)
        });
    }


    /**
     * set repeating roster
     *
     * @return {promise} 
     */
    setRepeatingRosterData(companyId, data){
        return this.firestoreApi.collection('repeatingRoster')
        .doc(companyId).update(FirestoreUtil.removeMetaData(data));
    }

    /**
     * get Company Repeating Roster Settings By Day And Week
     *
     * @return {repeatingRosterSetting[]} 
     */
    getCompanyRepeatingRosterSettingsByIsoDayAndWeekType(companyId, dayIso, weekType){
        // console.log('getCompanyRepeatingRosterSettingsByIsoDayAndWeekType',{companyId, dayIso, weekType});
        return this.firestoreApi.collection('repeatingRoster/'+companyId+'/setting')
        .where('dayIso', '==', parseInt(dayIso))
        .where("weekType", '==', weekType)
        .get().then((docs) => {
            return FirestoreUtil.parseCollectionData(docs)
         });
    }
    

    /**
     * add Billing Object
     *
     * @param {object} billingObject
     * @return {promise} 
     */
     addBillingObject(billingObject){
        return this.firestoreApi.collection('billingRecords').add(billingObject);
    }
    
    /**
     * add Park State Object
     *
     * @param {object} parkState
     * @return {promise} 
     */
    postParkState(parkState){
        return this.firestoreApi.collection('parkStates').doc(ParkUtil.getParkStateFirestoreDocId(parkState)).set(parkState);
    }

    /**
     * get Park State Object
     *
     * @param {string} parkStateId
     * @return {parkState} 
     */
    getParkStateById(parkStateId){
        return this.firestoreApi.collection('parkState')
        .doc(parkStateId)
        .get().then((doc) => {
           return FirestoreUtil.parseCollectionItemData(doc)
        });
    }

    
    
    /**
     * get rosterSettingForDay and parkStatesForDay
     *
     * @param {company} company
     * @param {date} date object with date property in moment object 
     * @return {promise} 
     */
    getRosterBookingData(company, selectedDate) {
        var selectedDate_isoDay = selectedDate.format("E");
        var selectedDate_weekType = DateUtil.getDateWeekType({date: selectedDate});

        // LogUtil.devOnly('getRosterBookingData',{'company' : company.id, selectedDate : DateUtil.dbFormat(
        //     selectedDate
        // )});

        return Promise.all([
            this.getCompanyRepeatingRosterSettingsByIsoDayAndWeekType(
                company.id,
                selectedDate_isoDay,
                selectedDate_weekType
            ),
            this.getCompanyDayParkStatuses(
                company.companyId,
                DateUtil.dbFormat(selectedDate)
            ),
        ]);
      }

    /**
     * get array of actions to proceed roster booking for day
     *
     * @param {company} company
     * @param {date} selectedDate object with date property in moment object 
     * @param {repeatingRosterSetting[]} rosterSettingForDay array of objects for selected day
     * @param {parkStates[]} parkStatesForDay array of objects for selected day
     * @return {promise} 
     */
    getActionsToProceedRosterBookingForDay(company, selectedDate, rosterSettingForDay, parkStatesForDay){
        var rosterToProceedAvailableBooking = rosterSettingForDay.filter(
            (x) =>
                // no park states
              !ArrayUtil.isNonEmptyArray(parkStatesForDay) 
              || 
              // park states exists but none is related to the same park id
              (ArrayUtil.isNonEmptyArray(parkStatesForDay) && 
                parkStatesForDay.findIndex(
                  (ps) => ps.lockType && ps.parkId === x.parkId
                ) === -1)
          );
        //   LogUtil.devOnly('rosterToProceedAvailableBooking ',rosterToProceedAvailableBooking);
        return rosterToProceedAvailableBooking.map(rrs => {
            console.log('book park by roster '+JSON.stringify(ParkUtil.getBookActionObjectFromRepeatingRoster(company.companyId, rrs, {'date' : selectedDate})));
            return this.postParkState(
                ParkUtil.getBookActionObjectFromRepeatingRoster(company.companyId, rrs, {'date' : selectedDate})
            );
          } 
          );
    }

    /**
     * post dispute comment
     *
     * @param {string} uid
     * @param {string} companyId
     * @param {string} comment
     * @param {string} plateNumber
     * @return {promise} 
     */
    postDisputeComment(uid, companyId, comment, plateNumber) {
        return this.firestoreApi.collection('disputeComments').add({
            uid: uid,
            companyId: companyId,
            comment: comment,
            plateNumber: plateNumber,
            createdUnix: DateUtil.getServerTime()
        });
    }

    /**
     * post dispute comment
     *
     * @param {string} companyId
     * @param {string} plateNumber
     * @return {promise} 
     */
    getProfileByPlateNumber(plateNumber, companyId) {
        return this.firestoreApi.collection('profiles')
        .where("companyId", "==", companyId)
        .where('numberPlates', 'array-contains', plateNumber)
        .limit(1)
        .get().then((docs) => {
           return FirestoreUtil.parseFirstItemFromCollectionData(docs)
        });
    }
    
  
  }
  
  
  export default FirestoreQueryUtil;
  
