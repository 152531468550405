import { Box, Button, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as profileActions from "../../actions/profile";
import CompanyUtil from "../../shared/utils/CompanyUtil";
import ArrayUtil from "../../shared/utils/general/ArrayUtil";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";
import UserUtil from "../../shared/utils/UserUtil";
import ProfilePlateNumbers from "./ProfilePlateNumbers";

class InfoUpdateDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  shouldShowNewInfo = () => {
    return this.showPlateNumbersUpdate();
  };

  showPlateNumbersUpdate = () => {
    const { userInfo } = this.props;
    const company = UserUtil.getCompany(userInfo);
    const profile = UserUtil.getProfile(userInfo);
    console.log("showPlateNumbersUpdate profile", profile);
    if (
      CompanyUtil.allowParkDisputesHelp(company) &&
      !ArrayUtil.isNonEmptyArray(profile.numberPlates)
    ) {
      return true;
    }
    return false;
  };

  getNewUpdateText = () => {
    const { userInfo } = this.props;
    if (this.showPlateNumbersUpdate()) {
      const profile = UserUtil.getProfile(userInfo);
      return (
        <Box>
          <Box pb={2}>
            {TranslatorUtil.t(
              "Please add your vehicles number plate to help others identify your car when parking."
            )}
          </Box>
          <Box textAlign={"right"}>
            <ProfilePlateNumbers
              profile={profile}
              reloadAfterSave={false}
              showTextButton={true}
              afterClose={() => window.location.reload()}
            />
          </Box>
        </Box>
      );
    }
    return null;
  };

  handleRedirect = () => {
    this.setState({ open: false });
    if (this.getRedirectUrl()) {
      this.props.history.push(this.getRedirectUrl());
    }
  };

  getRedirectUrl = () => {
    return null;
  };

  render() {
    const { open } = this.state;

    return (
      this.shouldShowNewInfo() && (
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="new-info-dialog"
          open={open}
        >
          <DialogTitle id="new-info-dialog" disableTypography={true}>
            <Typography variant="h5" color="primary">
              {TranslatorUtil.t("Flex Parking update")}
            </Typography>
          </DialogTitle>
          <div>
            <Box px={2} py={0}>
              <Box pl={1} className="text-muted">
                {this.getNewUpdateText()}
              </Box>
            </Box>

            <Box p={2}>
              <Grid
                spacing={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button variant="text" onClick={this.handleClose}>
                  {TranslatorUtil.t("Cancel")}
                </Button>
                {this.getRedirectUrl() && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleRedirect}
                  >
                    {TranslatorUtil.t("Update")}
                  </Button>
                )}
              </Grid>
            </Box>
          </div>
        </Dialog>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...profileActions,
      },
      dispatch
    ),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfoUpdateDialog)
);
