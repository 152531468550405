//this is super important because of circular dependency to go after export
import objectPath from "object-path";
import moment from "moment";

import UserUtil from "./UserUtil";
import LogUtil from "./general/LogUtil";
import constants from '../constants/constants';
import plans from '../constants/plans';
import Util from "./general/Util";
import FormatterUtil from "./general/FormatterUtil";
import ParkUtil from "./ParkUtil";
import DateUtil from "./general/DateUtil";
import ArrayUtil from "./general/ArrayUtil";
import PlanConstants from "../constants/plans";



/** 
 * Class helper to have all company logic in one place 
 * this shared folder is also watched and copied to 
 * functions folder to be reused there
*/
class CompanyUtil {
  
  /**
   * Returns company display name
   *
   * @param {company} company - company from firestore
   * @return {string} 
   */
  static getDisplayName(company) {
    return objectPath.get(company, 'displayName', objectPath.get(company, 'companyId', 'unknown'))
  }

  /**
   * Returns company plan name
   *
   * @param {company} company - company from firestore
   * @return {string}
   */
  static getPlanName(company) {
    var plan = plans.options.find(x => x.value === objectPath.get(company, 'plan'));
    return objectPath.get(plan, 'label', 'unknown');
  }

  /**
   * Returns parks limit
   *
   * @param {company} company - company from firestore
   * @return {number}
   */
  static getParksLimit(company) {
    return Number(objectPath.get(company, 'parksLimit', 0));
  }

  /**
   * Check if company park limit has exceeded
   *
   * @param {company} company - company from firestore
   * @param {park[]} parks - array of company's park (firestore)
   * @return {boolean}
   */
  static isParkLimitExceeded(company, parks) {
    var limit = CompanyUtil.getParksLimit(company);
    var parksCount = parks && parks.length ? parks.length : 0;
    return limit !== 0 && parksCount >= limit;
  }

  /**
   * Check if company is disabled
   *
   * @param {company} company - company from firestore
   * @return {boolean}
   */
  static isDisabled(company) {
    return objectPath.get(company, 'disabled', false);
  }

  /**
   * Check if company is on free plan
   *
   * @param {company} company - company from firestore
   * @return {boolean}
   */
  static isFreePlan(company) {
    return objectPath.get(company, 'plan', plans.DEFAULT_PLAN) === plans.FREE_PLAN;
  }

  /**
   * Check if company free plan has exceeded
   *
   * @param {company} company - company from firestore
   * @return {boolean}
   */
  static hasFreePlanExceeded(company) {
    var created = moment(objectPath.get(company, 'createdUnix', null));
    var _30daysAgo = moment().subtract(constants.FREE_PLAN_DAYS, 'd');
    LogUtil.devOnly('hasFreePlanExceeded', created, _30daysAgo);
    return created.isBefore(_30daysAgo, 'day');
  }

  /**
   * Get remaining free days fro company account
   *
   * @param {company} company - company from firestore
   * @return {number}
   */
  static freePlanDaysLeft(company) {
    var created = moment(objectPath.get(company, 'createdUnix', null));
    var today = moment();
    var diff = created.diff(today, 'days');

    return diff <= 0 ? constants.FREE_PLAN_DAYS + diff : 0;
  }


  /**
   * Check if company has valid next user in priority queue
   *
   * @param {priorityQueueUsers} priorityQueueUsers - from company (firestore)
   * @param {nextPriorityUserEmail} nextPriorityUserEmail - from company (firestore)
   * @return {boolean}
   */
  static hasValidNextUserSet(priorityQueueUsers, nextPriorityUserEmail) {
    return nextPriorityUserEmail && priorityQueueUsers.find(x => Util.compareCaseInsensitive(nextPriorityUserEmail,objectPath.get(x, 'email', '')));
  }

  /**
   * Get company priority queue
   *
   * @param {company} company - company from firestore
   * @return {array} array of emails
   */
  static getPriorityQueue(company) {
    return objectPath.get(company, 'priorityQueue', []);
  }

  /**
   * Get company priority queue users
   *
   * @param {company} company - company from firestore
   * @param {profile[]} profiles - company profiles (firestore)
   * @param {invitation[]} invitations - company invitations (firestore)
   * @return {profile[]} profiles - array of user profiles (firestore)
   */
  static getPriorityQueueUsers(company, profiles, invitations) {
    var priorityQueue = CompanyUtil.getPriorityQueue(company);
    var usersQueue = [];
    priorityQueue.forEach(x => {
      var user = profiles.find(u => Util.compareCaseInsensitive(x,objectPath.get(u, 'email', '')));
      if (typeof user === 'undefined') {
        var invitation = invitations.find(u => Util.compareCaseInsensitive(x,objectPath.get(u, 'email', '')));
        if (typeof invitation !== 'undefined') {
          user = UserUtil.getTempUserInvitationByEmail(x,company.companyId);
        }
      }
      if (user) {
        usersQueue.push(user);
      }

    });
    return usersQueue;
  }


  /**
   * Get users and invitations
   *
   * @param {profile[]} profiles - company profiles (firestore)
   * @param {invitation[]} invitations - company invitations (firestore)
   * @param {boolean} sortAlphabetically - sort alphabetically
   * @return {array} array of user profiles (firestore) with fake profiles made from invitations
   */
  static getUsersAndInvitations(profiles, invitations, sortAlphabetically = true) {
    var all = profiles.map(x => x);//clone array
    var profileEmails = profiles.map(x => x.email);
    invitations.filter(x => !profileEmails.includes(x.email)).forEach(x => {
      all.push(UserUtil.getTempUserInvitationByEmail(x.email,x.companyId));
    });
    return sortAlphabetically ? ArrayUtil.sortObjectsAlphabetically(all,'email') : all;
  }

  /**
   * Get next priority user email
   *
   * @param {company} company - company from firestore
   * @return {string} email or null if not present
   */
  static getNextPriorityUserEmail(company) {
    return objectPath.get(company, 'nextPriorityUserEmail', null);
  }

  /**
   * Get max parks per user per period (one week atm)
   *
   * @param {company} company - company from firestore
   * @return {number} default to constants.DEFAULT_PARKS_PER_USER_PER_PERIOD
   */
  static getMaxParksPerUserPerPeriod(company) {
    return CompanyUtil.includeWeekend(company) ? objectPath.get(company, 'maximumParksPerUser', constants.DEFAULT_PARKS_PER_USER_PER_PERIOD_INCLUDE_WEEKEND) : objectPath.get(company, 'maximumParksPerUser', constants.DEFAULT_PARKS_PER_USER_PER_PERIOD);
  }

  /**
   * Get latest time to unlock park
   *
   * @param {company} company - company from firestore
   * @return {number} default to constants.DEFAULT_LATEST_TIME_TO_UNLOCK
   */
   static getLatestTimeToUnlock(company) {
    return objectPath.get(company, 'latestTimeToUnlock', constants.DEFAULT_LATEST_TIME_TO_UNLOCK);
  }

  /**
   * Get latest time to unlock park
   *
   * @param {company} company - company from firestore
   * @return {number} default to constants.DEFAULT_LATEST_TIME_TO_UNLOCK
   */
  static hideBookedByForNormalUsers(company) {
    return objectPath.get(company, 'hideBookedByForNormalUser', constants.DEFAULT_HIDE_BOOKED_BY_FOR_NORMAL_USERS);
  }

  /**
   * If today compare time to let user unlock park
   *
   * @param {company} company - company from firestore
   * @param {boolean} isNormalUser - company from firestore
   * @param {selectedDate} selectedDate - object with date property in moment object 
   * @return {number} default to constants.DEFAULT_LATEST_TIME_TO_UNLOCK
   */
   static isNormalUserTooLateToUnlock(selectedDate, company, isNormalUser, serverOffsetTime = 0) {
    return isNormalUser && CompanyUtil.isTooLateToUnlock(selectedDate.date, company, serverOffsetTime);
  }

  /**
   * Check if user is next priority user
   *
   * @param {string} nextPriorityUserEmail - next priority user email
   * @param {profile} profile - user/profile firestore
   * @return {boolean}
   */
  static isNextPriorityUser(nextPriorityUserEmail, user) {
    return nextPriorityUserEmail && Util.compareCaseInsensitive(nextPriorityUserEmail,objectPath.get(user, 'email', ''));
  }


  /**
   * Get company visible days ahead
   *
   * @param {company} company - company from firestore
   * @return {boolean}
   */
   static getVisibleDaysAhead(company) {
    return objectPath.get(company, 'visibleDays', constants.DEFAULT_VISIBLE_DAYS_AHEAD);
  }
  

  /**
   * Filter profiles for the ones without park for by parks states (of day)
   *
   * @param {profile[]} companyProfiles - user/profile firestore
   * @param {park[]} companyParks - parks firestore
   * @param {parkstates[]} parkStatesForDay - parks firestore
   * @return {profile[]}
   */
   static getUsersWithoutPark(companyProfiles, companyParks, parkStatesForDay){
    return companyProfiles
    .filter((user)=>{
        //searching for users to update and these has at least logged in once so do not have to look into invitedOwnerEmail
        //all currently owned parks
        var ownedParks = companyParks.filter(x => ParkUtil.isUserCurrentOwner(x, user.id, user.email));  
        var unlockedOwnedPark = ownedParks.filter(p => parkStatesForDay.find(x => x.parkId === p.id && x.lockType === false && ParkUtil.isUserHolderOfParkState(x,user))  !== undefined);

        var userHasLockedPark = Array.isArray(parkStatesForDay) && parkStatesForDay.find(x => x && x.lockType && ParkUtil.isUserHolderOfParkState(x,user)) !== undefined;

        return unlockedOwnedPark.length >= ownedParks.length && !userHasLockedPark;
    });
  }

  /**
   * Get next priority user
   *
   * @param {company} company - company from firestore
   * @param {profile[]} profiles - company profiles from firestore 
   * @param {string} currentEmail - current priority user email
   * @return {boolean}
   */
  static getNextPriorityUser(company, users, currentEmail){
    var next = null;
    var currentFound = false;
    if(Array.isArray(company.priorityQueue)){
        company.priorityQueue.forEach(x => {
            if(!next && currentFound){
                next = users.find(u => FormatterUtil.compareStrings(u.email,x));
            }
            if(FormatterUtil.compareStrings(currentEmail,x)){
                currentFound = true;
            }
        });

        //take first in list if still empty
        if(!next && currentFound){
            company.priorityQueue.forEach(x => {
                if(!next){
                    next = users.filter(u => FormatterUtil.compareStrings(u.email,x));
                }
            });
        }
    }
    return next;
  }


  /**
   * Get new next priority email from queue
   *
   * @param {company} company - company from firestore
   * @return {string} newEmail
   */
  static getNewNextPriorityEmail(company){
    var next = null;
    if(company.nextPriorityUserEmail && Array.isArray(company.priorityQueue)){
        company.priorityQueue.forEach((x,index) => {
            if(!next && FormatterUtil.compareStrings(company.nextPriorityUserEmail, x)){
                if(company.priorityQueue[index+1]){
                    next = company.priorityQueue[index+1];
                } else {
                    next = company.priorityQueue[0];
                }
            }
        });
    }
    return next;
  }


  /**
   * Get company timezone
   *
   * @param {company} company - company from firestore
   * @return {string} result
   */
   static getTimezone(company){
    return objectPath.get(company,'timezone',constants.DEFAULT_TIMEZONE);
  }

  /**
   * Get current time by company timezone
   *
   * @param {company} company - company from firestore
   * @return {string} result
   */
   static getCurrentTimeByTimezone(company, format = 'HH'){
    return moment().tz(CompanyUtil.getTimezone(company)).format(format);
  }

  /**
   * Can send notification now by timezone check
   *
   * @param {company} company - company from firestore
   * @return {boolean} result
   */
   static isNotificationAllowedByTimezone(company){
    var companyCurrentHr = CompanyUtil.getCurrentTimeByTimezone(company);
    var companyCurrentHrInt = companyCurrentHr ? parseInt(companyCurrentHr) : null;
    if(companyCurrentHrInt > 0){
      return (constants.DISMISS_NOTIFICATIONS_FROM <= companyCurrentHrInt && constants.DISMISS_NOTIFICATIONS_UNTIL > companyCurrentHrInt);
    }
    return true;
  }

  /**
   * Get company billing day
   *
   * @param {company} company - company from firestore
   * @return {number} result
   */
   static getBillingDay(company){
    return parseInt(objectPath.get(company,'billingDay',constants.DEFAULT_BILLING_DAY));
  }

  /**
   * Get company plan
   *
   * @param {company} company - company from firestore
   * @return {number} result
   */
   static getPlanId(company){
    return parseInt(objectPath.get(company,'plan',PlanConstants.FREE_PLAN));
  }

  /**
   * Get company price per park
   *
   * @param {company} company - company from firestore
   * @return {number} result
   */
   static getPricePerPark(company){
    return Number(objectPath.get(company,'pricePerPark',constants.DEFAULT_PRICE_PER_PARK));
  }


  
  /**
   * Get company billing day
   *
   * @param {company} company - company from firestore
   * @param {number} parksCount - parks count
   * @param {number} profilesCount - profiles count
   * @return {number} result
   */
   static getCalculatedBilling(company,parksCount,profilesCount){
    return CompanyUtil.getPricePerPark(company) * parksCount;
  }


  /**
   * Get company billing day
   *
   * @param {company} company - company from firestore
   * @param {number} profilesCount - company profiles count from firestore 
   * @param {number} parksCount - company parks count from firestore 
   * @return {number} result
   */
   static getBillingObject(company, month, year, profilesCount, parksCount, createdRetrospectively = false){
     return {
      activeUsersCount: profilesCount,
      billingDay : CompanyUtil.getBillingDay(company),
      calculatedPrice : CompanyUtil.getCalculatedBilling(company,parksCount,profilesCount),
      companyId : company.companyId,
      createdRetrospectively: createdRetrospectively,
      createdUnix: DateUtil.getCurrentUnix(),
      month: String(month),
      year: String(year),
      parksCount: parksCount,
      plan: CompanyUtil.getPlanId(company),
      pricePerPark: CompanyUtil.getPricePerPark(company)
     };
  }

  /**
   * Get check if billing exist
   *
   * @param {billingRecords[]} billingRecords - company billingRecords from firestore 
   * @param {string} month - month
   * @param {string} year - year
   * @return {bool} result
   */
   static getBillingRecordExistCheck(billingRecords = [], month, year){
      return ArrayUtil.isNonEmptyArray(billingRecords) ? billingRecords.findIndex(br => br.month === month && br.year === year) !== -1 : false; 
   }

   /**
   * Get check billing total
   *
   * @param {billingRecords[]} billingRecords - company billingRecords from firestore 
   * @return {number} result
   */
    static getBillingsTotal(billingRecords = []){
      var total = 0;
      if(ArrayUtil.isNonEmptyArray(billingRecords)){
        billingRecords.forEach( br => 
          total +=  br.calculatedPrice
        );
      }
      return total;
   }

   /**
   * can normal users book more parks per day
   *
   * @param {company} company - company from firestore
   * @return {number} result
   */
   static canNormalUsersBookMoreParksPerDay (company) {
    return objectPath.get(company,'canNormalUsersBookMoreParksPerDay',false);
   }


   /**
   * check if company has this feature activated
   *
   * @param {company} company - company from firestore
   * @return {bool} result
   */
    static hasFeatureAllowed (company, prop, byDefault = true) {
      return objectPath.get(company,prop,byDefault) !== false;
    }

    static includeWeekend(company) {
      return objectPath.get(company, 'includeWeekend', constants.DEFAULT_INCLUDE_WEEKEND);
    }

    static allowParkDisputesHelp(company) {
      return objectPath.get(company, 'parkDisputesHelp', false);
    }

   /**
   * Get time when current day is disabled
   *
   * @param {company} company - company from firestore
   * @return {number} default to constants.DEFAULT_DISABLE_CURRENT_DAY_AT
   */
   static getDisableCurrentDayAt(company) {
      return parseInt(objectPath.get(company, 'disableCurrentDayAt', constants.DEFAULT_DISABLE_CURRENT_DAY_AT));
   }

   static getNewVisibleDayRevealHour(company){
    return objectPath.get(company, 'visibleDaytime', constants.DEFAULT_VISIBLE_DAYTIME_AHEAD);
   }

   static getNormalUsersDisallowedBookingAheadDays(company) {
    return objectPath.get(company, 'visibleDays', constants.DEFAULT_VISIBLE_DAYS_AHEAD);
  }
   
  // see UserUtil isNormalUserDisallowedFuture
   static isCompanyNormalUserInvisibleFutureDate(selectedDate, company, serverOffsetTime = 0){
    const allowedHoursToRevealNewDay = parseInt(CompanyUtil.getNewVisibleDayRevealHour(company));
    const companyCurrentTime = DateUtil.getServerTimeMoment(serverOffsetTime).tz(CompanyUtil.getTimezone(company));
    const visibleDaysAhead = CompanyUtil.getNormalUsersDisallowedBookingAheadDays(company);
    const lastVisibleDateUtc = DateUtil.createStartOfDateInUtc(companyCurrentTime).add(visibleDaysAhead, "d").subtract(1,'minute');
    const selectedDateUtc = DateUtil.createEndOfDateInUtc(selectedDate);
    const currentCompanyServerHours = parseInt(companyCurrentTime.format("H"));
    
    // console.log('isNormalUserDisallowedFuture- selectedDate ' +selectedDateUtc.toString()+' - lastVisibleDate ' +lastVisibleDateUtc.toString());

    if (DateUtil.isSameDate(selectedDateUtc, lastVisibleDateUtc)) {
      // console.log('is the same date -> check ours currentCompanyServerHours:'+currentCompanyServerHours+' allowedHoursToRevealNewDay:'+allowedHoursToRevealNewDay);
      if(allowedHoursToRevealNewDay > 0){
        return currentCompanyServerHours < allowedHoursToRevealNewDay;
      } else {
        return false;
      }
    }
    const selectedDateAfterLastVisibleDate =
        selectedDateUtc.isAfter(lastVisibleDateUtc);
    // console.log('ttt-selectedDateAfterLastVisibleDate ' + selectedDateAfterLastVisibleDate.toString());
    return selectedDateAfterLastVisibleDate;
   }

   static isCompanyInvisiblePastDate(selectedDate, company, serverOffsetTime = 0){
    const companyCurrentTime = DateUtil.getServerTimeMoment(serverOffsetTime).tz(CompanyUtil.getTimezone(company));
    const selectedDateUtc = DateUtil.createEndOfDateInUtc(selectedDate);
    const currentCompanyUtc = DateUtil.createDateTimeInUtc(companyCurrentTime);
    return selectedDateUtc.isBefore(currentCompanyUtc);
   }

   static isTooLateToBook(selectedDate, company, serverOffsetTime = 0) {
    const companyCurrentTime = DateUtil.getServerTimeMoment(serverOffsetTime).tz(CompanyUtil.getTimezone(company));
    const selectedDateUtc = DateUtil.createEndOfDateInUtc(selectedDate);
    const disableCurrentDayAt = CompanyUtil.getDisableCurrentDayAt(company);
    const currentCompanyUtc = DateUtil.createDateTimeInUtc(companyCurrentTime);

    if (DateUtil.isSameDate(selectedDateUtc, currentCompanyUtc)) {
      if(disableCurrentDayAt !== null && disableCurrentDayAt > 0){
        const currentCompanyServerHours = parseInt(companyCurrentTime.format("H"));
        return currentCompanyServerHours >= disableCurrentDayAt;
      } else {
        return false;
      }
    }
    return CompanyUtil.isCompanyInvisiblePastDate(selectedDate, company, serverOffsetTime);
  }

  static isTooLateToUnlock(selectedDate, company, serverOffsetTime = 0) {
    const companyCurrentTime = DateUtil.getServerTimeMoment(serverOffsetTime).tz(CompanyUtil.getTimezone(company));
    const selectedDateUtc = DateUtil.createEndOfDateInUtc(selectedDate);
    const latestTimeToUnlock = CompanyUtil.getLatestTimeToUnlock(company);
    const currentCompanyUtc = DateUtil.createDateTimeInUtc(companyCurrentTime);

    if (DateUtil.isSameDate(selectedDateUtc, currentCompanyUtc)) {
      if(latestTimeToUnlock > 0){
        const currentCompanyServerHours = parseInt(companyCurrentTime.format("H"));
        return currentCompanyServerHours >= latestTimeToUnlock;
      } else {
        return false;
      }
    }

    return CompanyUtil.isCompanyInvisiblePastDate(selectedDate, company, serverOffsetTime);
  }

}


export default CompanyUtil;



