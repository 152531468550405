import { Button, IconButton } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React from "react";
import ArrayUtil from "../../shared/utils/general/ArrayUtil";
import UserNumberPlatesDialog from "../form/UserNumberPlatesDialog";
import FirestoreQueryUtil from "../../shared/utils/FirestoreQueryUtil";
import firestoreApi from "../../firebase";
import TranslatorUtil from "../../shared/utils/general/TranslatorUtil";

export default class ProfilePlateNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile,
      showNumberPlatesEditProfile: null,
    };
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate", this.props, prevProps);

    if (
      this.props.profile?.id !== prevProps.profile?.id ||
      JSON.stringify(this.props.profile.numberPlates) !==
        JSON.stringify(prevProps.profile?.numberPlates)
    ) {
      this.setState({ profile: this.props.profile });
    }
  }

  onOpen = () => {
    const { profile } = this.state;
    this.setState({ showNumberPlatesEditProfile: profile })
   
    
  };

  afterClose = () => {
    const { showNumberPlatesEditProfile } = this.state;
    const { reloadAfterSave, afterClose } = this.props;
    if (reloadAfterSave) {
      new FirestoreQueryUtil(firestoreApi)
        .getProfileById(showNumberPlatesEditProfile.id)
        .then((profile) => {
          this.setState({ profile: profile });
        });
    }

    this.setState({ showNumberPlatesEditProfile: null });
    if(afterClose){
      afterClose();
    }
  };

  render() {
    const { showNumberPlatesEditProfile, profile } = this.state;
    const { showTextButton } = this.props;

    return (
      <div className="flex">
        <span style={{ whiteSpace: "pre-wrap" }}>
          {ArrayUtil.isNonEmptyArray(profile.numberPlates)
            ? profile.numberPlates.join(", \n")
            : !showTextButton ? "-" : ""}
        </span>
        {showTextButton ? (
          <Button
            startIcon={<EditOutlinedIcon />}
            onClick={this.onOpen}
            color="secondary"

          >
            {TranslatorUtil.t("Update number plates")}
          </Button>
        ) : (
          <IconButton
            aria-label="edit number plates"
            size="small"
            onClick={this.onOpen}
          >
            <EditOutlinedIcon fontSize="small" />
          </IconButton>
        )}
        {showNumberPlatesEditProfile ? (
          <UserNumberPlatesDialog
            profile={showNumberPlatesEditProfile}
            onClose={this.afterClose}
          />
        ) : null}
      </div>
    );
  }
}
